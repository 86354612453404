
import React from 'react';
import { MdRefresh } from 'react-icons/md';

function Spinner() {
    return (
        <div className="inline-block animate-spin ease duration-300 w-5 h-5 border-t-2 border-b-2 border-gray-900 rounded-full">
            <MdRefresh size={20} />
        </div>
    );
}

export default Spinner;