


import React from "react";
import tenpattiImage from "../../../src/Assest/images/liveCasinoimg/teenpatti (1).webp"
import hiilowImage from "../../../src/Assest/images/liveCasinoimg/hi-lo.webp"
import AnderBaharImage from "../../../src/Assest/images/liveCasinoimg/andar-bahar.webp"
import cardteenpatti2Image from "../../../src/Assest/images/liveCasinoimg/2-card-teenpatti.webp"
import amarakbaranthonyImage from "../../../src/Assest/images/liveCasinoimg/amar-akbar.webp"
import cardcasino32Image from "../../../src/Assest/images/liveCasinoimg/32-card-casino.webp"
import RouletteImage from "../../../src/Assest/images/liveCasinoimg/roulette.webp"
import lightlingImage from "../../../src/Assest/images/liveCasinoimg/lightning-dice.webp"
import crazyTimesImage from "../../../src/Assest/images/liveCasinoimg/crazy-time.webp"
import DealNoDealImage from "../../../src/Assest/images/liveCasinoimg/deal-no-deal.webp"
import MoneyWheelImage from "../../../src/Assest/images/liveCasinoimg/money-wheel.webp"
import DragonTigerImage from "../../../src/Assest/images/liveCasinoimg/dragon-tiger.webp"
import bgImage from "../../../src/Assest/images/liveCasinoimg/lcards.c25b9b0.png"
import { useNavigate } from "react-router-dom";

const sliderImage = [
    { imgs: tenpattiImage, title: "TeenPatti", gameId: 900000 },
    { imgs: hiilowImage, title: "Hii Low", gameId: 201153 },
    { imgs: AnderBaharImage, title: "Ander Bahar", gameId: 100000 },
    { imgs: cardteenpatti2Image, title: "Card Teenpatti", gameId: 600113 },
    { imgs: amarakbaranthonyImage, title: "Amar Akbar Anthony", gameId: 500001 },
    { imgs: cardcasino32Image, title: "32 Cards Casin", gameId: 201206 },

];
const casinoImage = [
    { imgs: RouletteImage, title: "Roulette", gameId: 900000 },
    { imgs: lightlingImage, title: "Lightening Dice", gameId: 201153 },
    { imgs: crazyTimesImage, title: "Crazy Times", gameId: 100000 },
    { imgs: DealNoDealImage, title: "Deal No Deal", gameId: 600113 },
    { imgs: MoneyWheelImage, title: "Money Wheel", gameId: 500001 },
    { imgs: DragonTigerImage, title: "Dragon Tiger", gameId: 201206 },

];


const Livecasino = () => {
    const navigate = useNavigate();

    const handalClickNavigate = (url) => {
        navigate('/app/iframe-casino/' + url)
    }
    return (
        <>
            <section className="lg:flex space-x-1 lg:space-y-0 space-y-2 w-full px-1 py-2">
                <div className="lg:h-44 h-40 lg:w-1/2 w-full border  rounded-lg  bg-no-repeat bg-origin-content bg-right-bottom bg-[#0C0710]  backdrop-blur  bg-[length:300px_100px] " style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="relative py-2 w-full flex">
                        <h2 className="relative text-white w-10/12 text-center font-extrabold text-[22px] not-italic">
                            Live Cards
                            <span className="absolute text-center font-extrabold top-0 transform translate-x-[-100%] text-[22.5px]  text-[#f37415] after:content-['Cards']"></span>
                        </h2>
                        <h2 className="w-2/12 text-[#f37415]">
                            {/* <a href="/login" class="text-decoration-none"> */}
                            More ...
                            {/* </a> */}
                        </h2>
                    </div>
                    <div className='grid grid-cols-3 gap-2'>
                        {sliderImage.map((item, index) => (
                            <div key={index} onClick={() => handalClickNavigate(item.gameId)} className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1">
                                <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                    <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">{item.title}</span>
                                    <span className="w-1/4 absolute  right-0 flex justify-center">
                                        <img src={item.imgs} height={30} width={30} />
                                    </span>
                                </span>
                            </div>
                        ))}

                    </div>
                </div>

                <div className="lg:h-44 h-40 lg:w-1/2 w-full border  rounded-lg  bg-no-repeat bg-origin-content bg-right bg-[#0C0710]  backdrop-blur  bg-[length:400px_200px]" style={{ backgroundImage: `url(${bgImage})` }}>
                    <div className="relative py-2 w-full flex">
                        <h2 className="relative text-white w-10/12 text-center font-extrabold text-[22px] not-italic">
                            Live Casino
                            <span className="absolute text-center font-extrabold top-0 transform translate-x-[-100%] text-[22.5px]  text-[#f37415] after:content-['Casino']"></span>
                        </h2>
                        <h2 className="w-2/12 text-[#f37415]">
                            {/* <a href="/login" class="text-decoration-none"> */}
                            More ...
                            {/* </a> */}
                        </h2>
                    </div>

                    <div className="py-2 grid grid-cols-3 gap-2 ">
                        {casinoImage.map((item, index) => (
                            <div key={index} onClick={() => handalClickNavigate(item.gameId)} className="lg:h-10 h-8 shadow-md flex flex-initial rounded-full border-2  shadow-[#8154f1]  bg-gradient-to-r from-[#130c2d] to-[#1904e5] relative lg:p-2 p-1">
                                <span className="w-full flex items-center  relative transition-opacity lg:px-1 px-2">
                                    <span className="w-3/4 text-ellipsis items-center text-white lg:text-sm text-[.6rem]">{item.title}</span>
                                    <span className="w-1/4 absolute  right-0 flex justify-center">
                                        <img src={item.imgs} height={30} width={30} />
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Livecasino;

