import React, { useState, useEffect } from "react";
import Marquee from "react-fast-marquee";
import { IoCloseSharp } from "react-icons/io5";
import { websiteName } from "../../globleConstent/membership/membership";

export const MarqueeNotifection = () => {
    const [showMarquee, setShowMarquee] = useState(true);
    const [domainSetting, setDomainSetting] = useState(null);

    const handleClose = () => {
        setShowMarquee(false);
    };

    useEffect(() => {
        try {
            const storedData = localStorage.getItem("clientdomainSetting");
            if (storedData) {
                setDomainSetting(JSON.parse(storedData));
            } else {
                console.error("No data found in localStorage for clientdomainSetting");
                setDomainSetting({});
            }
        } catch (error) {
            console.error("Error parsing JSON from localStorage:", error);
            setDomainSetting({});
        }
    }, []);

    return (
        <>
            {showMarquee && domainSetting?.clientNotification && (
                <div className="bg-black text-white px-4 py-1 flex justify-between items-center">
                    <Marquee speed={100}>
                        <span className="text-sm capitalize">
                            {domainSetting?.clientNotification || ""}
                        </span>
                    </Marquee>
                    <button
                        onClick={handleClose}
                        className="text-white hover:text-gray-200 focus:outline-none"                    >
                        <IoCloseSharp />
                    </button>
                </div>
            )}
        </>
    );
};
