import React, {  useState } from "react";
import { useDispatch } from "react-redux";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { userAction } from "../../../redux/action/userAction";
import { toast } from 'react-toastify';

const ChangeMobileNo = () => {
    const [mobileNumber, setMobileNumber] = useState({ phoneNumber: '' });
    const [errorsChangePassword, setErrorsChangePassword] = useState({});
    const [isFetching, setIsFetching] = useState(false);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setMobileNumber((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const dispatch = useDispatch();

    let user = JSON.parse(localStorage.getItem('clientUser'));


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validationChangeMobile()) {
            setIsFetching(true);
            try {
                const changePasswordData = {
                    "name": mobileNumber && mobileNumber.phoneNumber ? mobileNumber.phoneNumber : null,
                    "userId": user?.data?.userId

                };
                setMobileNumber({ phoneNumber: '' })
                await dispatch(userAction.userUpdate(changePasswordData));

            } catch (error) {
                if (error.response && error.response.data && error.response.data.changePassword) {
                    // toast.error(error.response.data.changePassword);
                } else {
                    const toastId = toast.error("Something went wrong. Please try again.");
                    setTimeout(() => toast.dismiss(toastId), 1000);
                    // toast.error("Something went wrong. Please try again.");
                }
            } finally {
                setIsFetching(false);
            }
        }
    };

    const validationChangeMobile = () => {
        let errors = {};
        let formIsValid = true;

        if (!mobileNumber.phoneNumber) {
            formIsValid = false;
            errors.oldPassword = 'Old Password Cannot Be Blank.';
        }
        setErrorsChangePassword(errors);
        return formIsValid;
    };

    const isValueExceedsSeven = () => {
        return mobileNumber?.phoneNumber?.length > 7;
    };

    return (
        <>
            <NavigatePathComponent showPathName={"Reset Password"} />
            <section className="w-full bg-black max-w-7xl mx-auto flex justify-center py-4 px-2">
                <div className="relative mx-auto text-center w-full max-w-sm bg-trasparent border-2 border-[#f36c21] px-3 shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
                    <div className="w-full my-6">
                        <span className="text-white text-sm font-semibold capitalize">Please provide new mobile number</span>
                        <div className="flex w-full gap-3 py-2 px-1 mt-4">

                            <div className="w-full pb-2 relative group">

                                <label class="transform transition-all absolute top-0 left-0 h-full flex items-center  text-sm text-gray-400 focus-group-focus-within:text-xs group-focus-within:text-white peer-valid:text-xs group-focus-within:h-1/2 peer-valid:h-1/2 group-focus-within:-translate-y-full peer-valid:-translate-y-full group-focus-within:pl-0 peer-valid:pl-0">Mobile No*</label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    className="peer text-white bg-black mt-1 w-full border-b border-gray-300 px-0 py-1 placeholder:text-gray-400 focus:border-gray-500 focus:outline-none"
                                    value={mobileNumber.phoneNumber}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        <div className={`${isValueExceedsSeven() ? 'bg-[#f36c21] text-white' : 'bg-[#1F1F1F] text-gray-400 '}  py-2 md:mt-4 rounded-full font-bold text-sm  uppercase cursor-pointer`} onClick={handleSubmit}>
                            update
                        </div>
                    </div>
                </div>
            </section>

        </>

    );
};

export default ChangeMobileNo;
