import React, { useState, useEffect } from "react";
import { SidebarRightList } from './SidebarRightList'
import { Link, NavLink, useNavigate } from "react-router-dom";
import whatsapp from '../../../Assest/images/social/whatsapp.svg'
import { toast } from "react-toastify";
import leftBlue from "../../../Assest/images/left-blue.webp"
import { B2C } from "../../../globleConstent/membership/membership";



export default function SidebarRight({ children, isOpenRightSidebar, setIsOpenRightSidebar, isOpen }) {

    const navigate = useNavigate();
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    const handleItemClick = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };
    // useEffect(() => {
    //     const savedIndex = localStorage.getItem('activeIndex');
    //     if (savedIndex !== null) {
    //         setActiveIndex(parseInt(savedIndex));
    //     }
    // }, []);

    // useEffect(() => {
    //     if (activeIndex !== null) {
    //         localStorage.setItem('activeIndex', activeIndex);
    //     } else {
    //         localStorage.removeItem('activeIndex');
    //     }
    // }, [activeIndex]);

    // Handal Hover Mouse Change icon 
    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    let Balance = JSON.parse(localStorage.getItem('clientBalance'));
    let Exposure = JSON.parse(localStorage.getItem('clientExposure'));
    let user = JSON.parse(localStorage.getItem('clientUser'));

    function logoutUser() {
        // dispatch(logout())
        localStorage.removeItem('clientUser');
        localStorage.removeItem('clientBalance');
        localStorage.removeItem('clientExposure');
        navigate('/')
        const toastId = toast.error("User! Logout successfully.");
        setTimeout(() => toast.dismiss(toastId), 1000);
    }
    const handleCloseBack = () => {
        setIsOpenRightSidebar(false)
    }

    let domainSetting = null;

    try {
        const storedData = localStorage.getItem('clientdomainSetting');
        if (storedData) {
            domainSetting = JSON.parse(storedData);
        } else {
            console.error('No data found in localStorage for clientdomainSetting');
            domainSetting = {};  // Provide a default fallback value
        }
    } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
        domainSetting = {};  // Provide a fallback value in case of error
    }

    return (
        <main className={`fixed overflow-hidden z-10 bg-gray-900 bg-opacity-50 inset-0 transform ease-in-out ${isOpenRightSidebar ? 'block' : 'hidden'}`}>
            <section className={" w-screen max-w-[17rem] right-0 absolute bg-black  h-full shadow-xl"}>
                <article className="relative w-screen max-w-[17rem] flex flex-col overflow-y-auto  h-full border-l border-l-[#f36c21]">
                    <header className="py-6 px-2 font-light text-md text-white " style={{ backgroundImage: `url(${leftBlue})` }}>
                        <span className="font-bold">Hi {user?.data?.username}</span><br />
                        {/* <span>You are in the <b>Blue</b> Tier</span> */}
                    </header>
                    {children}
                    <div className="h-full text-white ">
                        <div className="py-2 w-full px-3 ">
                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Wallet Amount
                                    <br /><p className="text-[10px]">(Inclusive bonus)</p></span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">{Balance}</span>
                            </div>

                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Net Exposure</span>
                                <span className="text-red-600 flex-1 text-md font-bold">{Math.abs(Exposure)}</span>

                            </div>

                            {/* <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Bonus</span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">0</span>
                            </div> */}

                            <div className="flex justify-center items-center py-1">
                                <span className="flex-1 font-normal text-sm">Available Withdrawal</span>
                                <span className="text-[#f36c21] flex-1 text-md font-bold">{Balance}</span>
                            </div>


                        </div>

                        <div className="py-2">
                            {/* <div className="px-2 py-2">
                                <div className=" w-full flex justify-between px-4 py-2 text-sm uppercase font-bold rounded-full border border-[#f36c21]">
                                    <span className=" ">Awaiting Bonus</span>
                                    <span className="text-[#f36c21]">0</span>
                                </div>
                            </div> */}
                            {B2C === true && (
                                <div className="py-1.5 w-full px-2 flex gap-1">
                                    <Link to={'/app/wallet/deposit'}>
                                        <div className="px-6 max-w-2/6 py-2 bg-[#f36c21] text-sm rounded-full" onClick={() => handleCloseBack()}>
                                            <span className="whitespace-nowrap">Deposit</span>
                                        </div>
                                    </Link>

                                    <Link to={'/app/wallet/withdrawal'}>
                                        <div className=" max-w-4/6 px-4 py-2 text-sm rounded-full bg-[#f36c21]" onClick={() => handleCloseBack()}>
                                            <span className="whitespace-nowrap ">Withdrawal Now</span>
                                        </div>
                                    </Link>
                                </div>
                            )}


                            {/* <div className="py-1.5 w-full px-2 flex gap-3">
                                <div className="text-center uppercase px-4 w-full py-2 bg-[#f36c21] text-sm rounded-full ">
                                    <span className="whitespace-nowrap  ">Refer And Earn</span>
                                </div>
                            </div> */}
                        </div>

                        {/* list of the sidebar data  */}
                        <div className="py-1">
                            <ul className="">
                                {SidebarRightList?.map((item, index) => {
                                    // Conditional rendering for "My Wallet" based on B2C
                                    if (item.title === "My Wallet" && !B2C) {
                                        return null;
                                    }

                                    return (
                                        <li
                                            key={index}
                                            className={`border-t ${item.title === "Open Bets" ? "bg-[rgb(243,107,33)]" : ""} ${activeIndex === index ? "bg-[rgb(243,107,33)]" : ""}`}
                                        >
                                            <NavLink
                                                to={item.link}
                                                className='flex items-center p-4 text-gray-900 dark:text-white group hover:bg-[rgb(243,107,33)]'
                                                onMouseEnter={() => handleMouseEnter(index)}
                                                onMouseLeave={handleMouseLeave}
                                                onClick={() => handleCloseBack() || handleItemClick(index)}
                                            >
                                                <img
                                                    src={(index === hoveredIndex || index === activeIndex) ? item.icon2 : item.icon1}
                                                    alt={item.title}
                                                    className="h-5 w-5"
                                                />
                                                <span className=" text-sm px-3 text-white">{item.title}</span>
                                            </NavLink>
                                        </li>
                                    );
                                })}

                            </ul>
                        </div>

                        <div className="py-1.5 h-24 bg-[#424242] w-full text-center px-2 space-y-1 ">
                            <span className="whitespace-nowrap  w-full">Connect with us on</span>
                            <a href={`https://api.whatsapp.com/send?phone=${domainSetting?.whatsappNumber}`} title="Whatsapp" target="_blank" >
                                <div className="uppercase px-4 w-full py-2 bg-[#424242] cursor-pointer hover:bg-green-500 border flex justify-center items-center text-sm rounded-full gap-2 ">
                                    <span className="[&>svg]:h-6 [&>svg]:w-6 ">
                                        <img src={whatsapp} alt="whatapp" className="w-6 h-6" />
                                    </span>
                                    <span className="text-white">Whatsapp</span>
                                </div>
                            </a>
                        </div>
                        <div className="pb-20 w-full  flex gap-3">
                            <div className="text-center uppercase px-4 w-full py-2 bg-green-500 text-sm cursor-pointer" onClick={() => logoutUser()}>
                                <span className="whitespace-nowrap  " >Logout</span>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
            <section
                className=" w-screen h-full cursor-pointer "
                onClick={() => { setIsOpenRightSidebar(false); }}
            ></section>
        </main>
    );
}
