import React, {  useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { HeaderAuth } from '../Auth/Header/Header';
import Dashboard from '../../pages/Auth/Dashboard/Dashboard';
import ResetPassword from '../Auth/ResetPassword/ResetPassword';
import ChangeMobileNo from '../../pages/Auth/ChangeMobileNo/ChangeMobileNo';
import Deposit from '../../pages/Auth/Deposit/Deposit';
import Bets from '../Bets/Bets';
import Wallet from '../Auth/Wallet/Wallet';
import EditStake from '../../pages/Auth/EditStake/EditStake';
import MatchViewDeatils from '../../pages/Auth/MatchViewDetails/MatchViewDeatils';
import ProfitAndLoss from '../../pages/Auth/ProfitAndLoss/ProfitAndLoss';
import ProfitLossDetails from '../../pages/Auth/ProfitLossDetails/ProfitLossDetails';
import Profile from '../../pages/Auth/Profile/Profile';
import SidebarRight from "../../component/Auth/SidebarRight/SidebarRight"
import Withdrawal from '../../pages/Auth/Withdrawal/Withdrawal';
import SportsbookPage from '../../pages/Auth/SportsbookPage/SportsbookPage';
import LiveCasino from '../../pages/Auth/LiveCasino/LiveCasino';
import IframeCasino from '../Auth/IframeCasino/IframeCasino';
import Drawer from "../Auth/Drawer/Drawer"
import AffillatePartner from '../../pages/Auth/Affiliate/Affiliate';
import TermCondition from '../../pages/Auth/TearmCondition/TermCondition';
import Promotions from '../../pages/Auth/Promotions/Promotions';
import PrivacyPolicy from '../../pages/Auth/PrivacyPolicy/PrivacyPolicy';
import FaqsPage from '../../pages/Auth/FaqsPage/FaqsPage';
import AboutUs from '../../pages/Auth/AboutUs/AboutUs';
import Lottery from '../../pages/Auth/Lottery/Lottery';
import MobileFooter from '../Auth/MobileFooter/MobileFooter';
import Livecard from '../Auth/Livecard/Livecard';
import SlotGames from '../Auth/Slot Games/SlotGames';
import DepositWidrawal from '../../pages/Auth/DWT/DepositWidrawal';
import IframeQtech from '../Auth/IframeCasino/IframeQtech';
import AccountStatement from '../../pages/Auth/AccountStatment/AccountStatement';


function Layout(props) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);

    return (
        <section className='mainView'>
            <div className='bg-black h-full overflow-y-auto flex flex-col '>
                <HeaderAuth isOpen={isOpen} setIsOpen={setIsOpen} setIsOpenRightSidebar={setIsOpenRightSidebar} />
                <div className='rightView '>
                    <div>
                        <SidebarRight isOpen={isOpen} setIsOpen={setIsOpen} isOpenRightSidebar={isOpenRightSidebar} setIsOpenRightSidebar={setIsOpenRightSidebar} />
                        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
                    </div>
                    <Routes>
                        <Route path="/dashboard" element={<Dashboard isOpen={isOpen}/>} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/setting" element={<EditStake />} />
                        <Route path="/change-mobileno" element={<ChangeMobileNo />} />
                        <Route path="/wallet/deposit" element={<Deposit />} />
                        <Route path="/bets" element={<Bets />} />
                        <Route path="/wallet" element={<Wallet />} />
                        <Route path="/profitloss" element={<ProfitAndLoss />} />
                        <Route path="/account-statment" element={<AccountStatement />} />
                        <Route path="/profitloss/details/:marketId?" element={<ProfitLossDetails />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route path="/wallet/withdrawal" element={<Withdrawal />} />
                        <Route path="/iframe-casino/:gameId?" element={<IframeCasino />} />
                        <Route path="/iframe-qtech-casino/:gameId?" element={<IframeQtech />} />
                        <Route path="/viewMatchDetail/:marketId/:eventId" element={<MatchViewDeatils  {...props} />} />
                        <Route path="/sportsbook/page/:sportId?" element={<SportsbookPage {...props} />} />
                        <Route path="/livecasino/page/:sportId?" element={<LiveCasino {...props} />} />
                        <Route path="/livecard/page/:sportId?" element={<Livecard /> } />
                        <Route path="/slotgames/page/:sportId?" element={<SlotGames /> } />
                        <Route path='/affillate-partner' element={<AffillatePartner />} />
                        <Route path='/term-conditon' element={<TermCondition />} />
                        <Route path='/promotions' element={<Promotions />} />
                        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                        <Route path='/faqs' element={<FaqsPage />} />
                        <Route path='/about-us' element={<AboutUs />} />
                        <Route path='/loyalty' element={<Lottery />} />
                        <Route path='/deposit-widrawal-transaction' element={<DepositWidrawal />} />
                    </Routes>
                </div>
            </div>
            {/* <MobileFooter /> */}
        </section>
    );
}

export default Layout;
