import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { BiSolidError } from 'react-icons/bi';
import { httpPost } from '../../middelware/Http';
import moment from 'moment';
import NavigatePathComponent from '../Auth/Header/NavigatePathComponent/NavigatePathComponent';


function Bets() {
    const [active, setActive] = useState(false);
    const [betShow, setBetShow] = useState(false);
    const [fancyBetData, setFancyBetData] = useState([]);
    const [oddsBetData, setOddsBetData] = useState([]);


    useEffect(() => {
        fetchBetLists()
    }, [])

    const toggleAccordion = (index) => {
        setActive((prevState) => ({
            ...prevState,
            [index]: !prevState[index],
        }));
    };

    const openBets = () => {
        setBetShow(true);
    };

    const openBetsClose = () => {
        setBetShow(false);
    };


    const fetchBetLists = async (marketId) => {
        try {
            const BetListData = {
                fancyBet: true,
                isDeclare: false,
                oddsBet: true,
                // marketId: "1.228209816",
            };

            const userBetHistory = await httpPost('sports/betsList', BetListData);
            if (userBetHistory && userBetHistory.data) {
                const { fancyBetData, oddsBetData } = userBetHistory.data;
                const filteredFancyBetData = fancyBetData ? fancyBetData.filter(element => element.isDeclare === 0).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                const sortedOddsBetData = oddsBetData ? oddsBetData.slice().sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];
                setFancyBetData(filteredFancyBetData)
                setOddsBetData(sortedOddsBetData)
                return { fancyBetData: filteredFancyBetData, oddsBetData: sortedOddsBetData };
            }
        } catch (error) {
            console.error('Error fetching bet lists:', error);
            throw error;
        }
    };

    return (

        <>
            <NavigatePathComponent showPathName={"Open Bets"} />
            <section className="w-full bg-black max-w-7xl mx-auto h-fit flex justify-center">
                <div className="relative mx-auto text-center w-full max-w-6xl py-1 bg-[#1E1E1E] afterFooter  shadow-xl ring-1 ring-gray-900/5">
                    <div className='text-white flex gap-4 justify-center items-center'>
                        <span className={`py-4 cursor-pointer px-2 uppercase text-xs hover:bg-[#51311F] ${!betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`} onClick={() => openBetsClose()}>My Bets</span>
                        <span className={`py-4 cursor-pointer px-2 uppercase text-xs hover:bg-[#51311F] ${betShow ? "border-b-2 border-b-[#f36c21] text-[#f36c21]" : ""}`} onClick={() => openBets()}>My Markets</span>
                    </div>

                    {!betShow && (
                        <div className="px-3 space-y-3 py-4">

                            <div className="  w-full  border-[#F36C21] border rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(1)}>
                                <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                    <span className="text-white uppercase">Odds Bets</span>
                                    <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[1] ? 'rotate-180' : ''}`}>
                                        <MdKeyboardArrowDown size={20} className="text-white" />
                                    </span>
                                </div>
                                {active[1] && (
                                    <>
                                        {oddsBetData && oddsBetData.length > 0 && oddsBetData.some(element => element.oddsType === "matchOdds") && (
                                            <h5 className='text-start px-2 text-gray-300 py-0.5'>{oddsBetData.find(element => element.oddsType === "matchOdds").matchName}</h5>
                                        )}
                                        <div className='text-sm text-gray-300 font-bold text-start px-2 py-0.5'>Match Odds</div>
                                        <>
                                            {oddsBetData && oddsBetData.length > 0 ? (
                                                <div className="bet_list_table text-left">
                                                    {oddsBetData
                                                        .filter(element => element.oddsType === "matchOdds")
                                                        .map((element, index) => (
                                                            element.type === "L" && (


                                                                <div key={index} className={element.type === "N" ? 'pl-2 bg-[#F8D0CE] py-2' : 'pl-2 bg-[#A5D9FE] border-b py-2'}>
                                                                    <div>
                                                                        <p className='text-sm font-bold text-gray-900'>{element.teamName}</p>
                                                                        <p className="text-sm uppercase text-gray-800" >{element.oddsType}</p>
                                                                        <p className='text-sm text-gray-800'>Placed: {moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</p>
                                                                    </div>
                                                                </div>

                                                            )
                                                        ))}
                                                </div>
                                            ) : (
                                                <div className="rounded-b-lg text-center p-6">
                                                    <div className="flex justify-center">
                                                        <BiSolidError size={60} className="text-[#F36C21]" />
                                                    </div>
                                                    <div className="text-center text-sm font-bold">
                                                        <span className="text-[#F36C21]">No Unmatched Bets!</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    </>
                                )}

                            </div>

                            <div className="  w-full  border-[#F36C21] border rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(3)}>
                                <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                    <span className="text-white  uppercase">Book Maker Bets</span>
                                    <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[3] ? 'rotate-180' : ''}`}>
                                        <MdKeyboardArrowDown size={20} className="text-white" />
                                    </span>
                                </div>
                                {active[3] && (
                                    <>
                                        {oddsBetData && oddsBetData.length > 0 && oddsBetData.some(element => element.oddsType !== "matchOdds") && (
                                            <h5 className='text-start px-2 text-gray-300 py-0.5'>{oddsBetData.find(element => element.oddsType === "matchOdds").matchName}</h5>
                                        )}
                                        {/* <div className='text-sm text-gray-300 font-bold text-start px-2 py-0.5'>Match Odds</div> */}
                                        <>

                                            {oddsBetData && oddsBetData.length > 0 ? (
                                                <div className="bet_list_table text-left">
                                                    {oddsBetData
                                                        .filter(element => element.oddsType !== "matchOdds")
                                                        .map((element, index) => (
                                                            element.type === "L" && (


                                                                <div key={index} className={element.type === "N" ? 'pl-2 bg-[#F8D0CE] py-2' : 'pl-2 bg-[#A5D9FE] border-b py-2'}>
                                                                    <div>
                                                                        <p className='text-sm font-bold text-gray-900'>{element.teamName}</p>
                                                                        <p className="text-sm uppercase text-gray-800" >{element.oddsType}</p>
                                                                        <p className='text-sm text-gray-800'>Placed: {moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</p>
                                                                    </div>
                                                                </div>

                                                            )
                                                        ))}
                                                </div>
                                            ) : (
                                                <div className="rounded-b-lg text-center p-6">
                                                    <div className="flex justify-center">
                                                        <BiSolidError size={60} className="text-[#F36C21]" />
                                                    </div>
                                                    <div className="text-center text-sm font-bold">
                                                        <span className="text-[#F36C21]">No Unmatched Bets!</span>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    </>
                                )}

                            </div>

                  



                            <div className=" justify-between  w-full  border-[#F36C21] border rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(2)}>
                                <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                    <span className="text-white uppercase">Fancy Bets</span>
                                    <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[2] ? 'rotate-180' : ''}`}>
                                        <MdKeyboardArrowDown size={20} className="text-white" />
                                    </span>
                                </div>


                                {active[2] && (
                                    <>
                                        {fancyBetData && fancyBetData.length > 0 ? (
                                            <div className="bet_list_table  text-left">
                                                {fancyBetData.map((element, index) => (
                                                    <>{element.type === "N" && (
                                                        <div  >
                                                            <p className="pl-2 text-white" >{element.sessionName}</p>
                                                            <div key={index} className={element.type === "N" ? 'pl-2 bg-[#F8D0CE] d_bet_list_table' : 'bg-[#A5D9FE] pl-2'} >
                                                                <p><b>NO {element.sessionName}</b></p>
                                                                <p>{element.sessionName}</p>

                                                                <p>Placed: {moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</p>
                                                            </div>
                                                        </div>
                                                    )}
                                                        {element.type === "Y" && (
                                                            <div >
                                                                <p className="text-white" >{element.sessionName}</p>
                                                                <div key={index} className={element.type === "N" ? 'bg-[#F8D0CE]' : 'bg-[#A5D9FE]'} >
                                                                    <p><b>YES {element.sessionName}</b></p>
                                                                    <p>{element.sessionName}</p>

                                                                    <p>Placed: {moment(element.createdAt).format('DD/MM/YYYY, h:mm:ss A')}</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                ))}
                                            </div>
                                        ) : (
                                            <div className="  rounded-b-lg text-center p-6">
                                                <div className="flex justify-center">
                                                    <BiSolidError size={60} className="text-[#F36C21]" />
                                                </div>
                                                <div className="text-center text-sm font-bold">
                                                    <span className="text-[#F36C21]">No Unmatched Bets!</span>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </>
                                )}
                            </div>




                            {/* <div className=" justify-between  w-full  border-[#F36C21] border rounded-2xl transition-transform transform duration-200" onClick={() => toggleAccordion(4)}>
                            <div className="w-full text-start flex px-2 py-2 text-sm font-normal">
                                <span className="text-white">Premium SportBook</span>
                                <span className={`absolute right-3  h-5 w-5 rounded-full bg-[#f36c21] transition-transform transform duration-200 ${active[4] ? 'rotate-180' : ''}`}>
                                    <MdKeyboardArrowDown size={20} className="text-white" />
                                </span>
                            </div>
                        </div> */}
                        </div>
                    )}

                    {betShow && (
                        <>
                            <div className='h-56  items-center flex justify-center '>
                                <div className='flex-col justify-center items-center space-y-2 text-[#F36C21]'>
                                    <div className='flex justify-center items-center'>
                                        <BiSolidError size={60} />
                                    </div>
                                    <div className='text-xs font-bold uppercase leading-4 py-2'>
                                        Your markets will be shown here.
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </section>
        </>

    )
}

export default Bets