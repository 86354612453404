import Inplay from "../../../../Assest/images/SubHeaderImage/Inplay.webp"
import Cricket from "../../../../Assest/images/SubHeaderImage/Cricket.webp"
import Soccer from "../../../../Assest/images/SubHeaderImage/Soccer.webp"
import Tennis from "../../../../Assest/images/SubHeaderImage/Tennis.webp"
import PremiumNotebook from "../../../../Assest/images/SubHeaderImage/Premium-Notebook.webp";
import LiveCasino from "../../../../Assest/images/SubHeaderImage/Live-Casino.webp";
import Election from "../../../../Assest/images/SubHeaderImage/Election.webp";
import LiveCard from "../../../../Assest/images/SubHeaderImage/Live-Card.webp";
import SlotGame from "../../../../Assest/images/SubHeaderImage/Slot-game.webp";
import More from "../../../../Assest/images/SubHeaderImage/More.png";
import SportsBook from "../../../../Assest/images/SubHeaderImage/Sportsbook.webp";
import aVIATOR from "../../../../Assest/images/aVIATOR.png"




export const SubHeaderMenu = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/app/dashboard',
    page: "dashboard",
    sportId: "",
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/app/sportsbook/page/',
    sportId: 4
  },
  {
    icon: Soccer,
    text: 'Soccer',
    link: '/app/sportsbook/page/',
    sportId: 1,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: '/app/sportsbook/page/',
    sportId: 2,
  },
  // {
  //   icon: PremiumNotebook,
  //   text: 'SportsBook',
  //   link: '#'
  // },
  {
    icon: LiveCasino,
    text: 'Live Casino',
    link: '/app/livecasino/page/',
    sportId: 111,
  },

  {
    icon: aVIATOR,
    text: 'Aviator',
    link: '/app/iframe-casino/',
    sportId: 201206,
  },

  {
    icon: Election,
    text: 'election',
    link: '/app/sportsbook/page/',
    sportId: 2003
  },
  {
    icon: aVIATOR,
    text: 'QTech',
    link: '/app/iframe-qtech-casino/',
    sportId: "SPB-aviator"
  },

  {
    icon: LiveCard,
    text: 'Live Card',
    link: '/app/livecard/page/',
    sportId: 7,
  },
  {
    icon: SlotGame,
    text: 'Slot Games',
    link: '/app/slotgames/page/',
    sportId: 8,
  }

];


export const SubHeaderMobile = [
  {
    icon: Inplay,
    text: 'Home',
    link: '/app/dashboard',
    sportId: "",
  },
  {
    icon: Cricket,
    text: 'Cricket',
    link: '/app/sportsbook/page/',
    sportId: 4,
  },
  {
    icon: Soccer,
    text: 'Football',
    link: "/app/sportsbook/page/",
    sportId: 1,
  },
  {
    icon: Tennis,
    text: 'Tennis',
    link: "/app/sportsbook/page/",
    sportId: 2,
  },
  {
    icon: LiveCasino,
    text: 'Casino',
    link: '/app/livecasino/page/',
    sportId: 111,
  },
  {
    icon: aVIATOR,
    text: 'Aviator',
    link: '/app/iframe-casino/',
    sportId: 201206,
  },

  // {
  //   icon: Election,
  //   text: 'election',
  //   link: '/app/sportsbook/page/',
  //   sportId: 2003
  // },
  {
    icon: aVIATOR,
    text: 'QTech',
    link: "/app/iframe-qtech-casino/",
    sportId: "SPB-aviator",
  },


  // {
  //   icon: More,
  //   text: 'More',
  //   link: "#"
  // }
]