import React, { useEffect, useState } from "react";
import { Header } from "../../component/Public/Header/Header.js";
import Drawer from "../../component/Drawer/Drawer.js";
import { httpPost } from "../../middelware/Http.js";
import { useParams, useNavigate } from "react-router-dom";
import { MdOutlineSportsScore} from "react-icons/md";
import Blinking from "../../component/Blinking/Blinking.js";
import axios from "axios";
import Sidebar from "../../component/Auth/SportList/Sidebar.js";
import io from "socket.io-client";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NavigatePathComponent from "../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent.js";
import fastimg from "../../../src/Assest/images/MAtchviewDetails/fast.055d02d.png"
import { PiTelevisionFill } from "react-icons/pi";
import { FaLock, FaLockOpen } from "react-icons/fa";

const PublicMatchView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [inplayMatch, setInplayMatch] = useState({});
  const [scoreShow, setScoreShow] = useState(true);
  const [matchScoreDetails, setMatchScoreDetails] = useState({});
  const [matchDetailsForSocketNew, setMatchDetailsForSocketNew] = useState({});
  const [finalSocket, setFinalSocketDetails] = useState({});
  const [tvShow, setTvShow] = useState(false);

  let { marketId, eventId } = useParams();

  const navigate = useNavigate();

  const handalClickNavigate = () => {
    navigate("/login")
  }
  
  useEffect(() => {

    const getMatchDataByMarketID = async () => {
      try {
        const resData = {
          marketId: marketId,
        };
        const inplayMatchResponse = await httpPost(
          "sports/sportByMarketId",
          resData
        );
        if (inplayMatchResponse && inplayMatchResponse.data) {
          setInplayMatch(inplayMatchResponse.data);
          callSocket(inplayMatchResponse?.data?.socketUrl, inplayMatchResponse?.data?.socketPerm, inplayMatchResponse?.data?.cacheUrl);
        }

      } catch (error) {
        console.error("Error fetching inplay data:", error);
      }
      finally {
        setIsLoading(false); // Mark loading as false regardless of success or failure
      }
    };




    // const fetchData = async () => {
    //   const result = await getMarketCacheUrl({
    //     cacheUrl: inplayMatch.cacheUrl,
    //   });

    //   try {
    //     setMatchScoreDetails(result);
    //   } catch (error) {
    //     console.error("Error fetching cache data:", error);
    //   }
    // };


    getMatchDataByMarketID();

    // fetchData();
    // getMarketCacheUrl()
  }, [
    inplayMatch.cacheUrl,
    inplayMatch.socketUrl,
    inplayMatch.socketPerm,
    inplayMatch.marketId,
    eventId,
  ]);

  const callSocket = async (socketUrl, socketPerm, cacheUrl) => {
    try {
      const socket = io.connect(socketUrl, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 99,
      });

      socket.emit(`marketByEvent`, eventId);
      socket.on(eventId, (data) => {
        setMatchDetailsForSocketNew(JSON.parse(data));
        filterData(JSON.parse(data));
      });

      if (socketPerm) {
        socket.emit("JoinRoom", marketId);
        socket.on(marketId, (data) => {

          setMatchScoreDetails(JSON.parse(data).result);
        });
      } else {
        await callCache(cacheUrl);
      }
    } catch (error) {
      console.error("Error in socket connection:", error);
    }
  };


  const callCache = async (cacheUrl) => {
    try {
      const interval = setInterval(async () => {
        await getMarketCacheUrl(cacheUrl);
      }, 1000);
      return () => clearInterval(interval);
    } catch (error) {
      console.error("Error calling cache:", error);
    }
  };

  const getMarketCacheUrl = async (cacheUrl) => {
    try {
      // if (!cacheUrl) {
      //   console.error("Cache URL is undefined or null");
      //   return; // Exit early if cacheUrl is undefin
      // }

      const response = await axios.get(cacheUrl);
      setMatchScoreDetails(response.data.result);
    } catch (error) {
      console.error("Error fetching cache data:", error);
    }
  };


  const filterData = (matchDetailsForSocketNew) => {
    try {
        if (!matchDetailsForSocketNew || matchDetailsForSocketNew.length === 0) {
            throw new Error("No data available to filter.");
        }

        const criteria = ['Tied Match', 'Match Odds', 'To Win the Toss'];

        const filteredData = Array.isArray(matchDetailsForSocketNew) ? matchDetailsForSocketNew.filter(item => criteria.includes(item.marketType)) : [];

        if (filteredData.length > 0) {
            const filteredDataObject = {};
            filteredData.forEach(item => {
                filteredDataObject[item.marketType] = item;
            });
            setFinalSocketDetails(filteredDataObject);
        } else {
            console.error("No data matched the criteria.");
        }
    } catch (error) {
        console.error("Error filtering data:", error);
    }
};





  // if (isLoading) {
  //   // Display skeleton loading while fetching data
  //   return (
  //     <div className="lg:w-5/6 w-full h-auto lg:px-1">
  //       <Skeleton count={2} />
  //     </div>
  //   );
  // }


  const handleOnClick = () => {
    navigate("/");
  };

  const handelScoreModal = () => {
    setScoreShow(true);
    setTvShow(false);
  };
  const handelTvModal = (url) => {
    // setTvShow(true);
    // setScoreShow(false);
    navigate(url);

  };

  const handelAllClossModal = () => {
    setTvShow(false);
    setScoreShow(!scoreShow);

  };

  // const handelScoreModal = () => {
  //   setScoreShow(!scoreShow);
  // };

  return (

    <div className="h-full">
      <div className="flex flex-col">
      {/* bg-black h-screen */}
        <div className="">
          <Header setIsOpen={setIsOpen} />

          <NavigatePathComponent showPathName={inplayMatch?.matchName} />
        </div>
        <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="w-full bg-black mb-6 overflow-y-auto py-1">
          <div className="flex w-full">
            <div className="lg:w-1/6  w-full hidden lg:block">
              <div className="w-full bg-black flex justify-center">
                <div className="w-11/12 border-2 border-[#f36c21]  rounded-3xl">
                  <Sidebar marketId={marketId} />
                </div>
              </div>
            </div>
            <div className="lg:w-5/6 w-full h-auto lg:px-1">
              {isLoading ? (
                <Skeleton count={2} />
              ) : (
                <>
                  <div className="border-t-gray-700 bg-gray-700 w-full px-2 py-1 flex gap-1 justify-end">
                    <div className="text-white flex gap-1 items-center justify-end py-2 bg-[#272727] px-2  rounded-md"
                      onClick={() => handelScoreModal()}
                    >
                      <span className="text-xs text-white px-2 ga-1 flex justify-center items-center font-bold ">
                        <span><MdOutlineSportsScore size={20} /></span>
                        <span>Scores</span>

                      </span>
                    </div>

                    <div
                      className="text-white flex gap-1 items-center bg-[#272727] px-2  rounded-md"
                      onClick={() => handelTvModal("/login")}
                    >
                      <span className="text-xs  text-white font-bold px-4 flex justify-center items-center gap-1">
                        <span><PiTelevisionFill size={20} /></span>
                        <span>Tv</span>

                      </span>
                    </div>

                    <div
                      className="text-white flex gap-1 items-center bg-green-800 px-4  rounded-md"
                      onClick={() => handelAllClossModal()}
                    >
                      <span className=" flex justify-center gap-x-1 items-center">
                        {tvShow || scoreShow ? (
                          <>
                            <FaLockOpen size={18} className="text-white" />
                            <span className="text-ms text-white font-bold ">
                              open
                            </span>
                          </>
                        ) : (
                          <>
                            <FaLock size={18} className="text-white" />
                            <span className="text-ms text-white font-bold ">
                              Close
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                  </div>

                  {inplayMatch.isTv ? <>
                    {tvShow && <div className="bg-white w-full  h-76">
                      <div className="details">
                        <div className={`w-full relative md:text-sm text-[10px]`}>
                          <iframe src={inplayMatch && inplayMatch.tvUrl ? inplayMatch.tvUrl : ""} title=" " loading='lazy' className="w-[100%] h-[300px]" />
                        </div>
                      </div>
                    </div>}
                  </>
                    : null}

                  {inplayMatch.isScore ? <>
                    {scoreShow && <div className="bg-white w-full h-[220px]">
                      <div className="details">
                        <div className={`w-full relative md:text-sm text-[10px]`}>
                          <iframe src={inplayMatch && inplayMatch.scoreIframe ? inplayMatch.scoreIframe : ""} title=" " loading='lazy' className="w-[100%] h-[220px]" />
                        </div>
                      </div>

                    </div>}
                  </>
                    : null}

                  <div className="bg-[#1e1e1e] h-auto">

                    <div className=" py-2 px-2 space-y-4">
                      {/* Match Odds Dat  */}
                      {inplayMatch?.isMatchOdds ? (
                        <>
                          {Object.values(finalSocket).map((element, index) => element.marketType === "Match Odds" && (
                            <div>
                              <div className="rounded-2xl">
                                <header className="bg-[#333333] rounded-t-2xl">
                                  <div className=" items-center flex relative z-0">
                                    <div className="w-4/12 rounded-tl-2xl py-2 px-2  bg-[#f36c21] flex">
                                      <span className="uppercase items-center inline-flex text-sm font-bold text-white">
                                        {element && element.marketType
                                          ? element.marketType
                                          : "NA"}
                                      </span>
                                      <span></span>
                                    </div>
                                    <div className="w-4/12"></div>
                                    <div className="w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center">
                                      <div className=" w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center">
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          1
                                        </span>
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          X
                                        </span>
                                        <span className="w-4/12 text-white text-sm font-normal text-center">
                                          2
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </header>

                                {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (
                                  <>
                                    <div
                                      className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                    >
                                      <div className="w-2/3  flex px-4">
                                        <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                          <span class="text-sm font-bold">
                                            <span>{elementtemp.selectionName}</span>

                                          </span>
                                        </div>

                                        {/* <div className="w-1/2  flex  ">
                                    <div className="w-1/2 flex justify-center items-center ">
                                      <span className="relative group">
                                        <IoIosPlay
                                          size={18}
                                          className="text-green-700"
                                        />
                                        <span className="absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md ">
                                          In-play
                                        </span>
                                      </span>
                                    </div>
                                    <div className="w-1/2 flex justify-center items-center">
                                      <span className="relative group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="green"
                                          stroke="green"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <circle cx="12" cy="12" r="2" />
                                          <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" />
                                        </svg>

                                        <span className="absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md ">
                                          play
                                        </span>
                                      </span>
                                    </div>
                                    <div className="w-1/2 flex justify-center items-center"></div>
                                  </div> */}
                                      </div>
                                      <div className="w-1/3 grid grid-cols-6 space-x-0.5 ">



                                        {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack.slice(1).map((tempData, index) => (
                                              <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"
                                                onClick={() => handalClickNavigate()}
                                              >

                                                <Blinking
                                                  price={tempData.price}
                                                  size={tempData.size}
                                                  color={"bg-[#a5d9fe]"}
                                                  blinkColor={"bg-[#CDEBEB]"}
                                                />
                                              </span>

                                            ))}
                                          </>
                                        ) : null}

                                        {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                          <>
                                            {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                              <span className="lg:col-span-1 col-span-3 py-1"
                                                onClick={() => handalClickNavigate()}
                                              >

                                                <Blinking
                                                  price={tempData.price}
                                                  size={tempData.size}
                                                  color={"bg-[#a5d9fe]"}
                                                  blinkColor={"bg-[#CDEBEB]"}
                                                />
                                              </span>

                                            ))}
                                          </>
                                        ) : null}



                                        {elementtemp &&
                                          elementtemp.ex &&
                                          elementtemp.ex.availableToLay &&
                                          elementtemp.ex.availableToLay.length >
                                          0 ? (
                                          elementtemp.ex.availableToLay.map(
                                            (tempData, index) => (
                                              <>
                                                {index === 0 ? (

                                                  <span className="lg:col-span-1 col-span-3 py-1"
                                                    onClick={() => handalClickNavigate()}>
                                                    <Blinking
                                                      price={tempData.price}
                                                      size={tempData.size}
                                                      color={"bg-[#f8d0ce]"}
                                                      blinkColor={"bg-[#CDEBEB]"}
                                                    />
                                                  </span>
                                                ) : (
                                                  <>
                                                    <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"
                                                      onClick={() => handalClickNavigate()}
                                                    >
                                                      <Blinking
                                                        price={tempData.price}
                                                        size={tempData.size}
                                                        color={"bg-[#f8d0ce]"}
                                                        blinkColor={"bg-[#CDEBEB]"}
                                                      />
                                                    </span>
                                                  </>
                                                )}



                                              </>

                                            )
                                          )) : null}
                                      </div>


                                    </div>

                                  </>
                                )) : null}
                              </div>
                            </div>
                          ))}
                        </>
                      ) : null}
                      {/* Match Odds Data  */}

                      {/* +++++++++++++++++++++++++ */}
                      {inplayMatch?.isBookmaker ? (
                        <div>

                          {matchScoreDetails &&
                            matchScoreDetails.team_data &&
                            matchScoreDetails.team_data.length > 0 ? (
                            <>
                              <header className="bg-[#333333] rounded-t-2xl">
                                <div className=" items-center flex relative z-0">
                                  <div className="w-2/3 flex ">
                                    <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                      Book Maker
                                    </div>
                                    <div className="w-1/2 px-2 flex items-center">
                                      <img src={fastimg} alt="textd" className="h-5 w-5" />
                                    </div>
                                  </div>

                                  <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                    <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-1  items-center">
                                      <span></span>
                                      <span></span>
                                      <span className="col-span-3 col-start-1 text-white text-sm font-normal text-right px-4">
                                        Yes
                                      </span>
                                      <span className="col-span-3  text-white text-sm font-normal text-left px-4">
                                        No
                                      </span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {matchScoreDetails &&
                                matchScoreDetails.team_data &&
                                matchScoreDetails.team_data.length > 0
                                ? matchScoreDetails.team_data.map(
                                  (commList, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >
                                        <div className="w-2/3 flex px-4">
                                          <div className="lg:w-1/2 w-full   leading-3 flex items-center text-slate-700">
                                            <span class="text-sm font-bold">
                                              <span>{commList.team_name}</span>
                                            </span>
                                          </div>
                                        </div>
                                        <>
                                          <div className="w-1/3  grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:block hidden "></span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3   lg:block "
                                              onClick={() => handalClickNavigate()}>
                                              <Blinking
                                                price={commList.lgaai}
                                                size={commList.khaai}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block "
                                              onClick={() => handalClickNavigate()}>
                                              <Blinking
                                                price={commList.khaai}
                                                size={commList.lgaai}
                                                color={"bg-[#98C8EA]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList.lgaai === "0.00" ? (
                                            <div className="w-1/3 px-0.5 right-0 h-full  absolute   grid grid-cols-6">
                                              <div className="relative bg-gray-600 opacity-80  border-gray-900 col-span-6 my-0.5"></div>
                                              <span className="py-1 mx-2 absolute rounded-full lg:col-start-1 2xl:col-start-3 col-span-3   bg-[#E0E0E0] text-[#000000DE] top-1/2 transform -translate-y-1/2">
                                                <span className="2xl:px-8 lg:px-8 px-2 text-nowrap">
                                                  SUSPENDED
                                                </span>
                                              </span>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                      {index <
                                        matchScoreDetails.commission_fancy_data
                                          .length -
                                        1 && <hr />}
                                    </>
                                  )
                                )
                                : null}
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      {/* +++++++++++++++++++++ */}

                      {/* Toss Data  */}
                      {inplayMatch?.isToss ? (
                        <div>

                          {matchScoreDetails &&
                            matchScoreDetails.toss_data &&
                            matchScoreDetails.toss_data.length > 0 ? (
                            <>
                              <header className="bg-[#333333] rounded-t-2xl">
                                <div className=" items-center flex relative z-0">
                                  <div className="w-2/3 flex ">
                                    <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                      Toss Market
                                    </div>
                                    <div className="w-1/2 px-2 flex items-center">
                                      <img src={fastimg} alt="textd" className="h-5 w-5" />
                                    </div>
                                  </div>

                                  <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                    <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-1  items-center">
                                      <span></span>
                                      <span></span>
                                      <span className="col-span-3 col-start-1 text-white text-sm font-normal text-right px-4">
                                        Yes
                                      </span>
                                      <span className="col-span-3  text-white text-sm font-normal text-left px-4">
                                        No
                                      </span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {matchScoreDetails &&
                                matchScoreDetails.toss_data &&
                                matchScoreDetails.toss_data.length > 0
                                ? matchScoreDetails.toss_data.map(
                                  (commList, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >
                                        <div className="w-2/3 flex px-4">
                                          <div className="lg:w-1/2 w-full   leading-3 flex items-center text-slate-700">
                                            <span class="text-sm font-bold">
                                              <span>{commList.team_name}</span>
                                            </span>
                                          </div>
                                        </div>
                                        <>
                                          <div className="w-1/3  grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:block hidden "></span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3   lg:block "
                                              onClick={() => handalClickNavigate()}>
                                              <Blinking
                                                price={commList.lgaai}
                                                size={commList.khaai}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block "
                                              onClick={() => handalClickNavigate()}>
                                              <Blinking
                                                price={commList.khaai}
                                                size={commList.lgaai}
                                                color={"bg-[#98C8EA]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList.lgaai === "0.00" ? (
                                            <div className="w-1/3 px-0.5 right-0 h-full  absolute   grid grid-cols-6">
                                              <div className="relative bg-gray-600 opacity-80  border-gray-900 col-span-6 my-0.5"></div>
                                              <span className="py-1 mx-2 absolute rounded-full lg:col-start-1 2xl:col-start-3 col-span-3   bg-[#E0E0E0] text-[#000000DE] top-1/2 transform -translate-y-1/2">
                                                <span className="2xl:px-8 lg:px-8 px-2 text-nowrap">
                                                  SUSPENDED
                                                </span>
                                              </span>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                      {index <
                                        matchScoreDetails.commission_fancy_data
                                          .length -
                                        1 && <hr />}
                                    </>
                                  )
                                )
                                : null}
                            </>
                          ) : null}
                        </div>
                      ) : null}

                      {/* Dynamic Fancy Data Seeeeeeection  */}
                      {inplayMatch?.isFancy && (
                        <div>
                          {matchScoreDetails &&
                            matchScoreDetails.commission_fancy_data &&
                            matchScoreDetails.commission_fancy_data.length > 0 ? (
                            <>
                              {/* <div className=" px-2 py-2 gap-4 flex flex-1 flex-grow-0 flex-shrink-0 my-1.5 w-full bg-gradient-to-t from-black/50 bg-opacity-50 to-gray-800 relative whitespace-nowrap  border-t-red-900 border-b-red-900">
                            <div className="shadow-lg py-1 border bg-[#F36E24] rounded-full  px-4 flex justify-center items-center ">
                              <span className="text-white text-sm uppercase font-normal">
                                fancy
                              </span>
                            </div>
                            <div className="uppercase text-base rounded-full font-normal px-4 bg-slate-400 flex justify-center items-center premium-animation v-tab">
                              Premium Cricket
                            </div>
                            <div></div>
                          </div> */}

                              <header className="bg-[#333333] rounded-t-2xl">
                                <div className=" items-center flex relative z-0">
                                  <div className="w-2/3 flex ">
                                    <div className="w-1/2 rounded-tl-2xl py-3 bg-[#f36c21]  uppercase px-2 items-center inline-flex text-sm font-bold text-white">
                                      fancy
                                    </div>
                                    <div className="w-1/2 px-2 flex items-center">
                                      <img src={fastimg} alt="textd" className="h-5 w-5" />
                                    </div>
                                  </div>

                                  <div className="w-1/3 bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center ">
                                    <div className="grid grid-cols-6 space-x-0.5 py-0.5 w-full bg-[#333333] rounded-tr-2xl  flex-wrap justify-center lg:px-4 px-1  items-center">
                                      <span></span>
                                      <span></span>
                                      <span className="col-span-3 col-start-1 text-white text-sm font-normal text-right px-4">
                                        No
                                      </span>
                                      <span className="col-span-3 text-white text-sm font-normal text-left px-4">
                                        Yes
                                      </span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </header>
                              {matchScoreDetails &&
                                matchScoreDetails.commission_fancy_data &&
                                matchScoreDetails.commission_fancy_data.length > 0
                                ? matchScoreDetails.commission_fancy_data.map(
                                  (commList, index) => (
                                    <>
                                      <div
                                        key={index}
                                        className={` bg-white relative shadow-sm flex decoration-none whitespace-normal max-w-full h-11`}
                                      >
                                        <div className="w-2/3 flex px-4">
                                          <div className="lg:w-1/2 w-full   leading-3 flex items-center text-slate-700">
                                            <span class="text-sm font-bold">
                                              <span>{commList.session_name}</span>
                                            </span>
                                          </div>
                                        </div>
                                        <>
                                          <div className="w-1/3  grid grid-cols-6 space-x-0.5 py-0.5">
                                            <span className=" lg:block hidden "></span>
                                            <span className=" lg:block hidden "></span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-3   lg:block "
                                              onClick={() => handalClickNavigate()}
                                            >
                                              <Blinking
                                                price={commList.runsNo}
                                                size={commList.oddsYes}
                                                color={"bg-[#E5C0BE]"}
                                                blinkColor={"bg-[#CDEBEB]"}
                                              />
                                            </span>
                                            <span className=" md:col-span-1 sm:col-span-2 col-span-3 md:col-start-4  lg:block "
                                              onClick={() => handalClickNavigate()}
                                            >
                                              <Blinking
                                                price={commList.runsYes}
                                                size={commList.oddsNo}
                                                color={"bg-[#98C8EA]"}
                                              />
                                            </span>
                                            <span className=" lg:block hidden"></span>
                                          </div>
                                          {commList &&
                                            commList.running_status &&
                                            (commList.running_status ===
                                              "SUSPENDED" ||
                                              commList.running_status === "CLOSE" ||
                                              commList.running_status ===
                                              "Ball Running") ? (
                                            <div className="w-1/3 px-0.5  h-full  absolute right-0  grid grid-cols-6">
                                              <div className="bg-gray-100 relative opacity-50  border-gray-900 md:col-start-3 col-span-6 my-0.5"></div>
                                              <span className="py-0.5 mx-2 absolute rounded-full md:col-start-3 col-span-2  bg-[#E0E0E0] text-[#000000DE] top-1/2 transform -translate-y-1/2">
                                                <span className=" px-3 text-nowrap">
                                                  {commList.running_status}
                                                </span>
                                              </span>
                                            </div>
                                          ) : null}
                                        </>
                                      </div>
                                      {index <
                                        matchScoreDetails.commission_fancy_data
                                          .length -
                                        1 && <hr />}
                                    </>
                                  )
                                )
                                : null}
                            </>
                          ) : null}
                        </div>
                      )}

                      {/* Dynamic Fancy Data Seeeeeeection  */}

                      {/*Tied Odds Dat a */}

                      {inplayMatch?.isTieOdds ? (
                        <>
                          {Object.values(finalSocket).map((elementMatchOdds, index) => elementMatchOdds.marketType === "Match Odds" && (
                            elementMatchOdds && elementMatchOdds.runners && elementMatchOdds.runners.length <= 2 ?
                              <>

                                {
                                  Object.values(finalSocket).map((element, index) => element.marketType === "Tied Match" && (
                                    <div>
                                      <div className="rounded-2xl divide-y">
                                        <header className="bg-[#333333] rounded-t-2xl">
                                          <div className=" items-center flex relative z-0">
                                            <div className="w-4/12 rounded-tl-2xl py-2 px-2 bg-[#f36c21] flex">
                                              <span className="uppercase items-center inline-flex text-sm font-bold text-white">
                                                {element && element.marketType
                                                  ? element.marketType
                                                  : "NA"}
                                              </span>
                                              <span></span>
                                            </div>
                                            <div className="w-4/12"></div>
                                            <div className="w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center">
                                              <div className=" w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center">
                                                <span className="w-4/12 text-white text-sm font-normal text-center">
                                                  1
                                                </span>
                                                <span className="w-4/12 text-white text-sm font-normal text-center">
                                                  X
                                                </span>
                                                <span className="w-4/12 text-white text-sm font-normal text-center">
                                                  2
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </header>

                                        {element && element.runners && element.runners.length > 0 ? element.runners.map((elementtemp, index) => (
                                          <>
                                            <div
                                              className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full `}
                                            >
                                              <div className="w-2/3  flex px-4">
                                                <div className="w-11/12  py-1 leading-3 flex items-center text-slate-700">
                                                  <span class="text-sm font-bold">
                                                    <span>{elementtemp.selectionName}</span>

                                                  </span>
                                                </div>

                                                {/* <div className="w-1/2  flex  ">
                                    <div className="w-1/2 flex justify-center items-center ">
                                      <span className="relative group">
                                        <IoIosPlay
                                          size={18}
                                          className="text-green-700"
                                        />
                                        <span className="absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md ">
                                          In-play
                                        </span>
                                      </span>
                                    </div>
                                    <div className="w-1/2 flex justify-center items-center">
                                      <span className="relative group">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          viewBox="0 0 24 24"
                                          fill="green"
                                          stroke="green"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <circle cx="12" cy="12" r="2" />
                                          <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" />
                                        </svg>

                                        <span className="absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md ">
                                          play
                                        </span>
                                      </span>
                                    </div>
                                    <div className="w-1/2 flex justify-center items-center"></div>
                                  </div> */}
                                              </div>
                                              <div className="w-1/3 grid grid-cols-6 space-x-0.5 ">



                                                {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                  <>
                                                    {elementtemp.ex.availableToBack.slice(1).map((tempData, index) => (
                                                      <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"
                                                        onClick={() => handalClickNavigate()}
                                                      >

                                                        <Blinking
                                                          price={tempData.price}
                                                          size={tempData.size}
                                                          color={"bg-[#a5d9fe]"}
                                                          blinkColor={"bg-[#CDEBEB]"}
                                                        />
                                                      </span>

                                                    ))}
                                                  </>
                                                ) : null}

                                                {elementtemp && elementtemp.ex && elementtemp.ex.availableToBack && elementtemp.ex.availableToBack.length > 0 ? (
                                                  <>
                                                    {elementtemp.ex.availableToBack.slice(0, 1).map((tempData, index) => (
                                                      <span className="lg:col-span-1 col-span-3 py-1"
                                                        onClick={() => handalClickNavigate()}
                                                      >

                                                        <Blinking
                                                          price={tempData.price}
                                                          size={tempData.size}
                                                          color={"bg-[#a5d9fe]"}
                                                          blinkColor={"bg-[#CDEBEB]"}
                                                        />
                                                      </span>

                                                    ))}
                                                  </>
                                                ) : null}



                                                {elementtemp &&
                                                  elementtemp.ex &&
                                                  elementtemp.ex.availableToLay &&
                                                  elementtemp.ex.availableToLay.length >
                                                  0 ? (
                                                  elementtemp.ex.availableToLay.map(
                                                    (tempData, index) => (
                                                      <>
                                                        {index === 0 ? (

                                                          <span className="lg:col-span-1 col-span-3 py-1"
                                                            onClick={() => handalClickNavigate()}>
                                                            <Blinking
                                                              price={tempData.price}
                                                              size={tempData.size}
                                                              color={"bg-[#f8d0ce]"}
                                                              blinkColor={"bg-[#CDEBEB]"}
                                                            />
                                                          </span>
                                                        ) : (
                                                          <>
                                                            <span className="lg:col-span-1 col-span-2 py-1 lg:block hidden"
                                                              onClick={() => handalClickNavigate()}
                                                            >
                                                              <Blinking
                                                                price={tempData.price}
                                                                size={tempData.size}
                                                                color={"bg-[#f8d0ce]"}
                                                                blinkColor={"bg-[#CDEBEB]"}
                                                              />
                                                            </span>
                                                          </>
                                                        )}



                                                      </>

                                                    )
                                                  )) : null}
                                              </div>


                                            </div>

                                          </>
                                        )) : null}
                                      </div>
                                    </div>

                                  ))
                                }
                              </> : null))
                          }
                        </>
                      ) : null}

                    </div>
                  </div>
                </>
              )}
            </div >
          </div >
        </div >
      </div >
    </div >

  );
};

export default PublicMatchView;
