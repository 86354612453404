// import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate, useParams } from 'react-router-dom';

// import Blinking from '../../Blinking/Blinking';
// import { IoIosPlay } from 'react-icons/io';
// import { userAction } from '../../../redux/action/userAction';

// function LiveUpcomingMatch() {

//     let { sportId } = useParams();
//     const [matchNames, setMatchNames] = useState([]);
//     const { sportmatchlist } = useSelector((state) => state.authentication);

//     useEffect(() => {
//      
//         dispatch(userAction.sportMatchList());
//     }, []);

//     useEffect(() => {
//         if (sportmatchlist && sportmatchlist.data) {

//             if (sportId) {
//                 const filteredAndMappedData = sportmatchlist.data?.filter(item => item.sportId == sportId);
//                 return setMatchNames(filteredAndMappedData);
//             }
//             setMatchNames(sportmatchlist.data);
//         }
//     }, [sportmatchlist, sportId]);

//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     const handleOnClick = () => {
//         navigate("/");
//     };
//     const handelClickViewMatch = (element) => {
//         navigate(`/app/viewMatchDetail/${element.marketId}/${element.eventId}`);
//         window.location.reload()
//     };

//     return (
//         <div className='relative space-y-4'>
//             <div>
//                 <div className='rounded-2xl '>
//                     <header className='bg-[#333333] rounded-t-2xl'>
//                         <div className=' items-center flex relative z-0'>
//                             <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
//                                 <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>
//                                     {/* <img src='https://thefairplay.io/_nuxt/img/cricket.28f3d5c.webp' alt='sportImg' width={22} height={22} /> */}
//                                 </span>
//                                 <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
//                                     {/* cricket */}
//                                 </span>
//                                 <span></span>
//                             </div>
//                             <div className='w-4/12'></div>
//                             <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
//                                 <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
//                                 </div>

//                             </div>
//                         </div>
//                     </header>
//                     {matchNames && matchNames.length > 0 ? matchNames.map((element, index) => (
//                         <>
//                             <div className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === matchNames.length - 1 ? 'rounded-b-2xl' : ''}`}
//                                 onClick={() => handelClickViewMatch(element)}
//                             >
//                                 <div className='w-2/3  flex px-4' >
//                                     <div className='w-1/2  py-1 leading-3 flex items-center text-slate-700 '>
//                                         <span class="text-sm font-bold truncate">
//                                             {element && element.matchName !== null || element.matchName !== undefined ? element.matchName : element.sportName}
//                                         </span>
//                                     </div>

//                                     <div className='w-1/2  flex  '>
//                                         <div className='w-1/2 flex justify-center items-center '>
//                                             <span className='relative group'>
//                                                 <IoIosPlay size={18} className='text-green-700' />
//                                                 <span className='absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md '>
//                                                     In-play
//                                                 </span>
//                                             </span>
//                                         </div>
//                                         <div className='w-1/2 flex justify-center items-center'>
//                                             <span className='relative group'>
//                                                 <svg
//                                                     xmlns="http://www.w3.org/2000/svg"
//                                                     width="20"
//                                                     height="20"
//                                                     viewBox="0 0 24 24"
//                                                     fill="green"
//                                                     stroke="green"
//                                                     stroke-width="2"
//                                                     stroke-linecap="round"
//                                                     stroke-linejoin="round"
//                                                 >
//                                                     <circle cx="12" cy="12" r="2" />
//                                                     <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14" />
//                                                 </svg>

//                                                 <span className='absolute hidden group-hover:block bg-gray-600 text-white px-1 py-1 text-sm font-bold rounded-md '>
//                                                     play
//                                                 </span>
//                                             </span>
//                                         </div>
//                                         <div className='w-1/2 flex justify-center items-center'></div>
//                                     </div>
//                                 </div>
//                                 <div className='w-1/3 grid grid-cols-6 space-x-0.5'>
//                                     <span className='lg:col-span-1 col-span-2'>
//                                         <Blinking price={10} size={"1cr+"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />

//                                     </span>
//                                     <span className='lg:block hidden lg:col-span-1 col-span-2'>
//                                         <Blinking price={10} size={"1cr+"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />

//                                     </span>
//                                     <span className='lg:col-span-1 col-span-2'>
//                                         <Blinking price={''} size={'-'} color={"bg-[#e1e1e1]"} />

//                                     </span>
//                                     <span className='lg:block hidden lg:col-span-1 col-span-2'>
//                                         <Blinking price={''} size={'-'} color={"bg-[#e1e1e1]"} />

//                                     </span>
//                                     <span className='lg:col-span-1 col-span-2'>
//                                         <Blinking price={10} size={"1cr+"} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />

//                                     </span>
//                                     <span className='lg:block hidden lg:col-span-1 col-span-2'>
//                                         <Blinking price={10} size={"1cr+"} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} isLast={index === matchNames.length - 1} />

//                                     </span>
//                                 </div>
//                             </div>
//                             {index < matchNames.length - 1 && <hr />}
//                         </>
//                     )) : (null)}
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default LiveUpcomingMatch;


import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Blinking from '../../Blinking/Blinking';

function LiveUpcomingMatch() {

    let { sportId } = useParams();
    const [matchData, setMatchData] = useState([]);
    const [matchName, setMatchName] = useState('')
    const { sportmatchlist } = useSelector((state) => state.authentication);



    useEffect(() => {
        if (sportmatchlist && sportmatchlist.data) {

            if (sportId) {
                const filteredAndMappedData = sportmatchlist.data?.filter(item => item.sportId == sportId);
                if (sportId) {
                    const filteredAndMappedData = sportmatchlist.data?.filter(item => item.sportId == sportId);
                    let sportName = ''
                    if (sportId === "4") {
                        sportName = "Cricket"
                    } else if (sportId === "2") {
                        sportName = "Tennis"
                    }
                    else if (sportId === "1") {
                        sportName = "Football"
                    } else if (sportId === "2003") {
                        sportName = "Election"

                    }
                    setMatchName(sportName)
                    return setMatchData(filteredAndMappedData);
                }

                return setMatchData(filteredAndMappedData);
            }
            setMatchData(sportmatchlist.data);
        }
    }, [sportmatchlist, sportId]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleOnClick = () => {
        navigate("/");
    };
    const handelClickViewMatch = (element) => {
        navigate(`/app/viewMatchDetail/${element.marketId}/${element.eventId}`);
        window.location.reload()
    };

    return (
        <div className='relative space-y-4 px-1'>
            <div className='rounded-t-2xl '>
                {/* {matchData.length !== 0 &&  */}
                <header className='bg-[#333333] rounded-t-2xl'>
                    <div className=' items-center flex relative z-0'>
                        <div className='w-4/12 rounded-tl-2xl py-2  bg-[#f36c21] flex'>
                            <span className='inline-flex px-2  rounded items-center relative text-center overflow-hidden'>

                            </span>
                            <span className='uppercase items-center inline-flex text-sm font-bold text-white'>
                                {matchName ? matchName : ''}
                            </span>
                            <span></span>
                        </div>
                        <div className='w-4/12'></div>
                        <div className='w-4/12  bg-[#333333]  rounded-tr-2xl flex flex-wrap justify-center items-center'>
                            <div className=' w-full bg-[#333333] rounded-tr-2xl flex flex-wrap justify-center px-4 py-2 items-center'>
                            </div>

                        </div>
                    </div>
                </header>
                {/* // } */}

                {matchData && matchData.length > 0 ? matchData.map((element, index) => (
                    <React.Fragment key={index}>
                        <div className={` bg-white shadow-sm flex decoration-none whitespace-normal max-w-full ${index === matchData.length - 1 ? 'rounded-b-0' : ''}`}
                            onClick={() => handelClickViewMatch(element)}
                        >
                            <div className='w-2/3  flex px-4' >
                                <div className='w-11/12  py-1 leading-3 flex items-center text-slate-700 '>
                                    <span class="text-sm font-bold truncate">
                                        {element && element.matchName !== null || element.matchName !== undefined ? element.matchName : element.sportName}
                                        <span className='text-xs font-bold text-gray-500 flex items-center gap-1'>{element.matchDate}
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div className='w-1/3 grid grid-cols-6 space-x-0.5 text-black'>
                                <span className='lg:col-span-1 col-span-3 py-1'>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={1425} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                </span>
                                <span className='lg:col-span-1 col-span-3 py-1'>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={2124} color={"bg-[#f8d0ce]"} blinkColor={'bg-white'} />
                                </span>
                                <span className='lg:col-span-1 col-span-2 py-1 lg:block hidden '>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={2445} color={"bg-[#a5d9fe]"} />
                                </span>
                                <span className='lg:block hidden lg:col-span-1 col-span-2 py-1'>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={2452} color={"bg-[#f8d0ce]"} />
                                    {/* color={"bg-[#e1e1e1]"} */}
                                </span>
                                <span className='lg:col-span-1 col-span-2 py-1 lg:block hidden '>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={2525} color={"bg-[#a5d9fe]"} blinkColor={'bg-[#CDEBEB]'} />
                                </span>

                                <span className='lg:col-span-1 col-span-2 py-1 lg:block hidden '>
                                    <Blinking price={Math.floor(Math.random() * 100)} size={2142} color={"bg-[#f8d0ce]"} blinkColor={'bg-[#CDEBEB]'} />
                                </span>
                            </div>
                        </div>
                        {index < matchData.length - 1 && <hr />}
                    </React.Fragment>
                )) :
                    <div className='py-2 px-2 bg-gray-300'>
                        <span className='text-sm font-medium px-2 py-1 text-gray-800 uppercase'>
                            Data Not Found
                        </span>

                    </div>
                }
            </div>
        </div>
    )
}

export default LiveUpcomingMatch;