import React, { useEffect, useState } from "react";
import { Header } from "../../component/Public/Header/Header.js";
import Footer from "../../component/Footer/Footer.js";

import Banner from "../../component/banner/banner.js";
import DownloadApp from "../../Assest/images/Download-App.png";
import Crown from "../../Assest/images/Crown.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Payment from "../../component/payment/payment.js";
import { B2C, memberShip } from "../../globleConstent/membership/membership.js";
import Livecasino from "../../component/Livecasino/livecasino.js";
import Inplay from "../../component/Public/Inplay/Inplay.js";
import { useDispatch, connect } from 'react-redux';
import Drawer from "../../component/Public/Drawer/Drawer.js";
import img1 from "../../Assest/images/DashBoard/34a8b5b9-235d-42fa-8b3e-6237f1884488.webp"
import img2 from "../../Assest/images/DashBoard/895b5f21-8a84-4411-af32-3331dcba69dd.webp"
import img3 from "../../Assest/images/DashBoard/34a8b5b9-235d-42fa-8b3e-6237f1884488.webp"
import premium_new from "../../Assest/images/premium-new.gif"
import SportList from "../../component/Public/SportList/SportList.js";
import { websiteName } from "../../globleConstent/membership/membership.js"
import LiveCasinoHome from "../../component/Public/LiveCasinoHome/LiveCasinoHome.js";
import { userAction } from "../../redux/action/userAction.js";
import { useNavigate } from "react-router-dom";



export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isOpen]);
  const navigate = useNavigate();

  const images = [
    img1, img2, img3
    // Add more image URLs as needed
  ];



  const settings = {
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: true,
    slidesToShow: 3,
    centerMode: true,

    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplay: true,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          autoplay: true,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handalClickNavigate = (url) => {
    navigate(url)
  }

  return (
    <>
      <div className="slider hota hai wo hatan hai bootm se  wo header se hai">
        <div>
          <div className="bg-black h-full flex flex-col">
            <div className="">
              <Header setIsOpen={setIsOpen} />
            </div>
            <Drawer isOpen={isOpen} setIsOpen={setIsOpen} />
            <div className="w-full bg-black">
              <div className="flex w-full">
                <div className="lg:w-1/6  w-full hidden lg:block">
                  <SportList />
                </div>
                <div className="lg:w-5/6  w-full  lg:px-5">
                  <div className=" bg-black w-full">
                    <div className=" py-1 w-full">
                      <div className="w-full">
                        <Banner />
                      </div>
                    </div>
                  </div>
                  <div className="bg-black h-full bg-cover ">
                    <div className=" w-full flex flex-wrap">
                      <Inplay />
                    </div>

                    <Livecasino />

                    <div className="px-1">
                      <LiveCasinoHome />
                    </div>


                    {/* <div className=" w-full md:px-1 px-3 py-2">
                      <div className="w-full md:px-1 py-2">
                        <img
                          src={premium_new}
                          alt=""
                          className="w-full lg:h-64 md:h-36 h-16"
                        />
                      </div>
                    </div> */}

                  </div>
                </div>

              </div>
            </div>
            {/* <div className="h-full w-full py-4 md:pr-2 ">
              <div className=" w-full flex justify-center bg-[rgb(243,107,33)] ">
                <div className=" xl:w-[64%] w-full flex py-4 md:gap-6 gap-3 items-center">
                  <div className="lg:w-2/5 w-1/2 flex items-center justify-center">
                    <span>
                      <img src={DownloadApp} alt="download" title="download" />
                    </span>
                  </div>
                  <div className="lg:w-3/5 w-1/2 flex items-center">
                    <div className="py-4">
                      <span className="md:text-4xl text-2xl flex lg:justify-start justify-center font-semibold text-white uppercase leading-2 tracking-wide">
                        <h2>Download The App</h2>
                      </span>
                      <div className="py-3 text-left text-base text-white font-medium">
                        <h4>
                          Now download the {websiteName} app and get easier, quicker
                          access to your winnings!
                        </h4>
                      </div>
                      <span>
                        <a href="#">
                          <button
                            type="button"
                            class=" py-2.5 px-5 me-2 mb-2 text-sm md:text-xl md:tracking-wide text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-200  focus:z-10 focus:ring-4  dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                          >
                            Download App
                          </button>
                        </a>
                      </span>

                      <p className="text-sm text-white ">
                        Download now outside Google Playstore
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="club lootry">
              <div className=" w-full bg-black p-2">
                <div className=" w-full flex justify-center items-center">
                  <div className="xl:w-[64%] md:w-[94%] w-full ">
                    {/* <div className="first top w-ful">
                      <div className="flex justify-center items-center">
                        <div>
                          <span className="flex justify-center items-center">
                            <img src={Crown} alt="crown" />
                          </span>
                          <span className="md:text-4xl text-2xl text-white py-3 font-serif font-medium">
                            <h4 className="py-2"> CLUB LOYALTY</h4>
                          </span>
                        </div>
                      </div>
                      <section className="lg:py-1 ">
                        <div className="mx-auto lg:py-5 max-w-7xl ">
                          <div className="">
                            <div className="">
                              <>
                                <Slider
                                  {...settings2}
                                  className="flex  items-center justify-center w-full overflow-hidden"
                                >
                                  {memberShip.map((card, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="md:py-5 md:p-1 p-4  px-1 lg:space-y-4 w-full rounded-xl  group  relative "
                                      >
                                        <div className="bg-white  w-full rounded-xl md:py-8 ">
                                          <div className="rounded-full flex justify-center py-2">
                                            <span
                                              className={`md:text-3xl text-sm md:-top-0 top-1  absolute px-5 py-1 flex justify-center text-black/80 bg-gradient-to-r ${card.backgroundColor} rounded-full flex justify-center`}
                                              style={{ zIndex: 999 }}
                                            >
                                              {card.title}
                                            </span>
                                          </div>
                                          <div className="flex gap-3 items-center justify-center ">
                                            <span className="md:text-xl text-sm md:font-medium uppercase">
                                              {" "}
                                              Turnover
                                            </span>
                                            <span className="text-black md:text-5xl text-3xl font-medium ">
                                              {card.amount}
                                            </span>
                                          </div>

                                          <h3 className="md:text-lg text-sm px-2 md:pt-6  text-black md:font-medium">
                                            {card.benefitTitle}
                                          </h3>
                                          <div className="text-gray-600 break-all transition duration-200 ease-in-out  lg:text-left text-justify">
                                            <ul className="list-disc md:px-12 px-7 md:marker:text-2xl marker:text-lg marker:text-[rgb(243,107,33)] text-black md:text-sm md:font-medium font-semibold text-xs">
                                              {card.benefits.map(
                                                (benefit, i) => (
                                                  <li key={i} className="">
                                                    {benefit}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                            <p className="px-6 py-2 text-xs text-black">
                                              {card.note}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </Slider>
                              </>
                            </div>
                          </div>
                          <div className="flex justify-center gap-2 py-2">
                            <button
                              type="button"
                              class="text-white bg-[rgb(243,107,33)]  focus:outline-none  font-medium rounded-full md:text-base text-sm md:px-8 px-3 py-2 text-center tracking-wide"
                            >
                              Know More
                            </button>
                            {B2C === true && (
                            <button
                              onClick={() => handalClickNavigate('/registration')}
                              type="button"
                              class="text-white bg-[rgb(243,107,33)]  focus:outline-none  font-medium rounded-full md:text-base text-sm md:px-8 px-3 py-2  text-center tracking-wide"
                            >
                              Register
                            </button>
                            )}
                          </div>
                        </div>
                      </section>
                    </div> */}

                    {/* <div className="w-full">
                      <div className=" ">
                        <div className="flex justify-center items-center w-full pt-8">
                          <div className="">
                            <h3 className="md:text-4xl text-xl text-white font-normal font-serif flex justify-center">
                              PROMOTIONS
                            </h3>
                            <p className="md:text-sm text-[10px] text-white font-serif flex justify-center px-8 text-center py-2">
                              Make the most of exciting offers ever so often on
                              {websiteName}! Watch out for various bonus offers and
                              exciting contests!
                            </p>
                          </div>
                        </div>
                        <div className="w-full slider py-5 md:px-10 px-4 ml-2">
                          <Slider {...settings}>
                            {images.map((imageUrl, index) => (
                              <div key={index} className="border-0 rounded-lg">
                                <img
                                  src={imageUrl}
                                  alt={`Slide ${index + 1}`}
                                  className=" border-0 rounded-lg lg:h-96  md:h-64 h-48  object-fill w-full "
                                />
                              </div>
                            ))}
                          </Slider>
                          <div className="flex justify-center gap-2 py-5">
                            <button
                              type="button"
                              className="text-white bg-[rgb(243,107,33)]  focus:outline-none  font-medium rounded-full md:text-base text-sm md:px-8 px-3 py-2 text-center tracking-wide"
                            >
                              Know More
                            </button>
                            <button
                              type="button"
                              className="text-white bg-[rgb(243,107,33)]  focus:outline-none  font-medium rounded-full md:text-base text-sm md:px-8 px-3 py-2  text-center tracking-wide"
                            >
                              Ragister
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div>
                      <div className="w-full">
                        <div className="flex justify-center items-center w-ful">
                          <h2 className="md:text-4xl text-xl text-white font-normal font-serif flex justify-center uppercase">
                            Payment method
                          </h2>
                        </div>

                        <div className="card flex justify-center">
                          <Payment />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {

  const { authentication } = state;
  return { authentication };
}
export default connect(mapStateToProps)(Home);