import React, { useState } from "react";
import NavigatePathComponent from "../../../component/Auth/Header/NavigatePathComponent/NavigatePathComponent";
import { FaUserEdit } from "react-icons/fa";
import { MdEmail, MdOutlineSmartphone, MdCake, MdCurrencyRupee } from "react-icons/md";
import { ImManWoman } from "react-icons/im";


const Profile = () => {
    const [isOpenRightSidebar, setIsOpenRightSidebar] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [openModal, setOpenModal] = useState(false)

    const handleInputChange = (event) => {
        setMobileNumber(event.target.value);
    };

    // Define a function to check if the input value exceeds 7
    const isValueExceedsSeven = () => {
        return mobileNumber.length > 7;
    };
    const editUser = () => {
        setOpenModal(!openModal)
    }
    const closeEditUser = () =>{
        setOpenModal(false)
    }

    return (
        <>
            <NavigatePathComponent showPathName={"Profile"} />

            <section className="w-full bg-black max-w-7xl mx-auto flex justify-center py-4">
                <div className="relative mx-auto text-center w-full afterFooter max-w-7xl bg-trasparent px-3 shadow-xl ring-1 ring-gray-900/5 sm:rounded-md">
                    <div className="w-full m-auto  max-w-5xl ">
                        <div className="pb-4">
                            <div className="h-24 py-2 px-2 bg-[#424242] w-full flex justify-center items-center gap-x-2 divide-x-2 divide-gray-500">
                                <span className="px-4 lg:text-lg text-sm font-bold text-gray-200 ">Your Current Turnover from (11-04-2024 to 11-05-2024) is</span>
                                <span className="px-4 lg:text-3xl text-xl font-bold text-white flex items-center">
                                    <MdCurrencyRupee />
                                    <h5>400</h5>
                                </span>

                            </div>

                            <div className="h-80  px-2 bg-[#212121] w-full">
                                <div className="h-52 wallet-slider m-auto flex items-center justify-center max-w-md">
                                    <div className="flex justify-center items-center flex-nowrap">
                                        <div className="wrapper">
                                            <div className="gauge">
                                                <div className="slice-colors">
                                                    <div className="st slice-item">
                                                    </div> <div className="st slice-item">
                                                    </div> <div className="st slice-item">
                                                    </div> <div className="st slice-item">
                                                    </div>
                                                </div>
                                                <div className="needle needle-blue "></div>
                                                <div className="gauge-center">
                                                    <div className=" mt-12">
                                                        ₹
                                                        400
                                                    </div>
                                                    <div className="caption text-xs font bold">Your Current Turnover</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="h-28 w-full max-w-md m-auto pt-4 px-2 space-y-3">
                                    <div className=" flex  justify-center items-center">
                                        <div className="flex-1 flex justify-center items-center gap-x-2">
                                            <span className="h-3 w-3 rounded-full bg-blue-500"></span>
                                            <span className="text-blue-500 text-sm font-normal capitalize">₹0 to Blue</span>
                                        </div>
                                        <div className="flex-1 justify-center flex  items-center gap-x-2">
                                            <span className="h-3 w-3 rounded-full bg-slate-400"></span>
                                            <span className="text-slate-400 text-sm font-normal capitalize">₹1K to Sliver</span>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="flex-1 justify-center flex  items-center gap-x-2">
                                            <span className="h-3 w-3 rounded-full bg-yellow-500"></span>
                                            <span className="text-yellow-500 text-sm font-normal capitalize">₹10L to Gold</span>
                                        </div>
                                        <div className="flex-1 justify-center flex  items-center gap-x-2">
                                            <span className="h-3 w-3 rounded-full bg-slate-300"></span>
                                            <span className="text-slate-300 text-sm font-normal capitalize">₹1Cr to Platinum</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="px-4 py-4 space-y-3 text-white">
                            <div className="flex justify-between items-center">
                                <div className="text-lg font-bold text-white whitespace-nowrap">Guest User (Your Details)</div>
                                <div onClick={() => editUser()}>
                                    <FaUserEdit size={25} className="text-orange-500" />
                                </div>
                            </div>

                            <div className="lg:flex text-start px-2 lg:py-3 py-1 items-center border-b-2">
                                <div className="lg:w-1/2 w-full flex items-center py-1 gap-x-2">
                                    <span className="text-orange-600"><MdEmail size={25} /></span>
                                    <span>1234567890@sikander777.com</span>
                                </div>
                                <div className="lg:w-1/2 w-full flex items-center py-1 gap-x-2">
                                    <span className="text-orange-600">< MdOutlineSmartphone size={25} /></span>
                                    <span>1234567890</span>
                                </div>
                            </div>

                            <div className="lg:flex text-start px-2 lg:py-3 py-1 items-center border-b-2">
                                <div className="lg:w-1/2 w-full flex items-center py-1 gap-x-2">
                                    <span className="text-orange-600"><ImManWoman size={25} /></span>
                                    <span>I prefer not to say</span>
                                </div>
                                <div className="lg:w-1/2 w-full flex items-center py-1 gap-x-2">
                                    <span className="text-orange-600">< MdCake size={25} /></span>
                                    <span>January 01,1990</span>
                                </div>
                            </div>

                        </div>
                        {openModal && <>
                            <div className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center px-4 items-center`}>
                                <div className="bg-[#121212] p-8 rounded-lg w-96">

                                    {/* <form className="text-start" > */}
                                    <div className="text-start">
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="accountNumber">Name*</label>
                                            <input
                                                type="text"
                                                id="accountNumber"
                                                name="accountNumber"
                                                // value={formData.accountNumber}
                                                // onChange={handleChange}
                                                className="border border-gray-300 rounded-md p-2 w-full  focus:ring focus:border-blue-500"
                                            />
                                            {/* {errors.accountNumber && <p className="text-red-500 text-xs mt-1">{errors.accountNumber}</p>} */}
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="confirmAccountNumber">Mobile Number</label>
                                            <input
                                                type="text"
                                                id="confirmAccountNumber"
                                                name="confirmAccountNumber"
                                                // value={existingAccount ? formData.accountNumber : formData.confirmAccountNumber}
                                                // onChange={handleChange}
                                                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                                            />
                                            {/* {errors.confirmAccountNumber && <p className="text-red-500 text-xs mt-1">{errors.confirmAccountNumber}</p>} */}
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="ifscCode">Date Of Birth*</label>

                                            <div className="relative max-w-sm">
                                                <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                        <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                    </svg>
                                                </div>
                                                <input datepicker type="date" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
                                            </div>

                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="accountHolder">Email</label>
                                            <input
                                                type="text"
                                                id="accountHolder"
                                                name="accountHolder"
                                                // value={formData.accountHolder}
                                                // onChange={handleChange}
                                                className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring focus:border-blue-500"
                                            />
                                            {/* {errors.accountHolder && <p className="text-red-500 text-xs mt-1">{errors.accountHolder}</p>} */}
                                        </div>
                                        <div className="mb-4">
                                            <label className="block text-sm font-medium text-gray-300 mb-1" htmlFor="bankName">Gender*</label>
                                            <select id="countries" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option selected>Male</option>
                                                <option value="US">Female</option>
                                                <option value="CA">Trasgender</option>

                                            </select>
                                        </div>

                                        <div className="flex justify-start">
                                            <button className="text-white p-2 text-xs" onClick={()=> closeEditUser()}>CLOSE</button>
                                            <button className="text-white py-3 px-4 rounded-md text-xs bg-orange-500 ">Update</button>


                                        </div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        </>
                        }

                    </div>
                </div>
            </section>

        </>

    );
};

export default Profile;
