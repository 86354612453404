import React from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';




const Banner = () => {

    const settings = {
        autoplay: true,
        autoplaySpeed: 2500,
        arrows: false,
        centerMode: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,

                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    autoplay: true,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let domainSetting = null;

    try {
      const storedData = localStorage.getItem('clientdomainSetting');
      
      if (storedData) {
        domainSetting = JSON.parse(storedData);
      } 
    } catch (error) {
      console.error("Error parsing clientdomainSetting from localStorage:", error);
    }


    return (
        <>
            <div className="slider">
                <Slider {...settings}>
                    {domainSetting?.banner
                        .sort((a, b) => a.priority - b.priority)
                        .map((image, index) => (
                            <div key={index} className="border-0 rounded-lg">
                              
                                <img
                                    src={image.image}
                                    alt={image.name}
                                    
                                    className="border-0 rounded-lg w-[97%] 2xl:h-64 lg:h-52 sm:h-48 h-32"
                                />
                            </div>
                        ))
                    }

                </Slider>
            </div>
        </>
    )
}

export default Banner;