import React, { useRef, useState, useEffect } from 'react'
import { RiInformationFill } from "react-icons/ri";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from 'react-router-dom';
import BetPlaceCounter from './BetPlaceCounter';

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};


export function BetPlaceMobile(props) {
  let { openBets, closeSec, closeRow, matchName, betSlipData, placeBet, count, betLoading, increaseCount, decreaseCount } = props;

  const myArray = Object.values(betChipsData);
  const modalRef = useRef();
  const [positions, setPositionData] = useState(0);

  useEffect(() => {


    if (betSlipData && betSlipData.position && betSlipData.position.length > 0) {
      betSlipData && betSlipData.position.forEach((eles) => {
        if (betSlipData.selectionId === eles._id) {
          setPositionData(eles.position);
        }
      });
    }


    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // handleClose(); // Close modal when clicking outside
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [betSlipData]);


  const [stake, setStack] = useState(0);
  let [placeButton, setPlaceButton] = useState(false)
  const updateInputValue = (event) => {
    const newValue = parseFloat(event.target.value);
    setStack(() => {
      const newStack = !isNaN(newValue) ? (newValue >= 0 ? newValue : 0) : 0;
      betSlipData.stake = newStack;
      if (betSlipData.stake > 0) {
        setPlaceButton(true);
      }
      if (betSlipData.stake <= 0) {
        setPlaceButton(false);
      }
      updateOddsPostModal()
      return newStack;
    });
  };


  const updateFinalBalance = (amount) => setStack(prevStack => {
    const newStack = prevStack + amount
    betSlipData.stake = newStack;
    if (betSlipData.stake > 0) {
      setPlaceButton(true);
    }
    if (betSlipData.stake <= 0) {
      setPlaceButton(false);
    }
    return newStack
  });


  if (betSlipData.oddsType === "fancy") {
    // filterdata = runCount.session.filter(session => session.Selection_id == betSlipData.data.Selection_id);
  }

  if (betSlipData.oddsType === "bookmaker") {
    // filterdata = runCount.team_data.filter(session => session.selectionid == betSlipData.data.selectionid);

  }




  const arrayData = (element) => {
    if (element > 0) {
      updateFinalBalance(element);
      updateOddsPostModal()
    }
  };

  const updateOddsPostModal = async () => {

    let oddsType = betSlipData.oddsType
    let positionArray = {}
    let positionArrayNew = {}

    if (oddsType == "Match Odds" || oddsType == "Tied Match") {
      betSlipData.nameOther.map((oddsData) => {
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake * (betSlipData.odds - 1)
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionId] = -1 * betSlipData.stake
        }
        if (oddsData.selectionId != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionId] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionId] ? betSlipData.position[oddsData.selectionId] : 0
        let calculatePos = positionArray[oddsData.selectionId]


        positionArray[oddsData.selectionId] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionId] = Number(calculatePos)

      })
    }

    if (oddsType == "toss" || oddsType == "bookmaker") {
      betSlipData.nameOther.map((oddsData) => {

        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid == betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake * (betSlipData.odds)
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "L") {
          positionArray[oddsData.selectionid] = -1 * betSlipData.stake
        }
        if (oddsData.selectionid != betSlipData.selectionId && betSlipData.betType == "K") {
          positionArray[oddsData.selectionid] = betSlipData.stake
        }

        let currentPos = betSlipData.position[oddsData.selectionid] ? betSlipData.position[oddsData.selectionid] : 0
        let calculatePos = positionArray[oddsData.selectionid]

        positionArray[oddsData.selectionid] = Number(calculatePos) + Number(currentPos)
        positionArrayNew[oddsData.selectionid] = Number(calculatePos)

      })
    }


    betSlipData.oldPos = betSlipData.position
    betSlipData.position = positionArray

  }


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


 

  const closeModal = () => {
    // assuming betSlipData and id are available here
    let id = closeSec;
    closeRow(id);
  };

  const handlePlaceBet = () => {
    Promise.resolve(placeBet())
      .then(closeModal)
      .catch((error) => {
        console.error("Error placing bet:", error);
      });
  };

  return (
    <section className='py-0.5'>


      <div ref={modalRef} className={`border-4 bg-white rounded-lg relative ${betSlipData.betType === "Y" || betSlipData.betType === "L" ? "border-[#A5D9FE]" : "border-[#F8D0CE]"}`}>
        <div className='py-2 px-2 '>
          <div className='text-sm font-normal space-y-1'>
            <div>{matchName}</div>
            <div>{betSlipData.name}</div>
          </div>
          <div className='text-sm font-normal py-1 mt-2 flex items-center space-x-3'> 
          <div className="relative mb-2 flex-1 flex border bg-transparent ">
          <button className="ml-3 " onClick={decreaseCount}>-</button>
            <input
              type="text"
              className="peer block min-h-[auto] w-full rounded  px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
              id="exampleFormControlInput1"
              value={
                betSlipData.oddsType === "fancy" || betSlipData.oddsType === "bookmaker" || betSlipData.oddsType === "toss"
                  ? (count * 100)
                  : count}
            />
            <label
              className="pointer-events-none outline-none bg-white absolute left-3 top-[-0.8rem] px-2 py-0.5 max-w-[80%] truncate text-neutral-500"
            >
              Runs
            </label>
            <button className="mr-3 " onClick={increaseCount}>+</button>
          </div>
            <div class="relative mb-2 flex-1">
              <input
                type="text"
                className="peer block min-h-[auto] w-full rounded border bg-transparent px-3 py-[0.32rem] leading-[1.6] outline-none focus:placeholder:opacity-100 peer-focus:text-primary"
                id="exampleFormControlInput1"
                placeholder=" Min. 100 Max. 25000 "
                value={stake ? stake : ""}
                onChange={updateInputValue}
              />
              <label
                className="pointer-events-none outline-none bg-white absolute left-3 top-[-0.8rem] px-2 py-0.5 max-w-[80%] truncate text-neutral-500"
              >
                Stack
              </label>
            </div>
          </div>

          <div className=' px-2 flex justify-between items-center'>
            <span className='px-1 py-1 text-sm font-normal'>or Choose You Stake Size</span>
            <NavLink to={"/app/setting"} className='px-2 py-1 text uppercase text-sm font-normal hover:bg-[#FEF4EE] hover:rounded-md text-[#F37027]'>Edit Stake</NavLink>
          </div>

          <div className=' grid grid-cols-4 py-1 px-2 gap-2 items-center'>
            {myArray && myArray.map((element, index) => (
              <div key={index} className="flex justify-center items-center bg-[#F07934] border rounded-full" onClick={() => arrayData(element)}>
                <span className='px-4 py-1.5 text-white text-xs font-light' >{element}</span>
              </div>
            ))}
          </div>

          <div className='text-sm font-normal py-1 px-2 gap-2 flex items-center'>
            <div class="relative mb-2 flex-1 text-center px-2  py-1 rounded-full border text-[#F37027] border-[#F37027] cursor-pointer text-sm uppercase" onClick={() => closeModal()}>
              <span className='py-4'>Cancel</span>
            </div>



            {placeButton === false ? <>
              <div
                class="relative mb-2 flex-1 px-2  text-center py-2 rounded-full bg-gray-400 text-gray-200 text-sm uppercase">
                <span className='py-4'>placebet</span>
              </div>
            </> :


              <div onClick={handlePlaceBet} class="relative mb-2 flex-1 px-2  text-center py-2 rounded-full bg-[#F07934] text-sm uppercase">
                <span className='py-4'>placebet</span>


              </div>
            }
          </div>
          <div className='flex justify-between gap-x-2 items-center px-2'>
            <div className="text-sm font-bold text-gray-800  text-start">
              {Array.isArray(betSlipData.nameOther) ? betSlipData.nameOther.map((other, index) => (
                <React.Fragment key={index}>
                  <div>{other && other.selectionName ? other.selectionName : other.team_name}</div>
                </React.Fragment>
              )) : null}
            </div>
            <div>
              {Array.isArray(betSlipData.nameOther) && betSlipData.nameOther.length < 3 ? betSlipData.nameOther.map((other, index) => (
                <React.Fragment key={index}>
                  <div className="text-sm font-bold">

                    {betSlipData.oddsType === "Match Odds" || betSlipData.oddsType === "Tied Match" ?
                      <span className={`${!isNaN(betSlipData.position[other.selectionId]) && parseFloat(betSlipData.position[other.selectionId]).toFixed(2).replace(/\.?0+$/, "") < 0 ? "text-red-500" : "text-green-700"} font-bold col-12 text-center`}>
                        {(!isNaN(betSlipData.position[other.selectionId]) ? parseFloat(betSlipData.position[other.selectionId]).toFixed(2).replace(/\.?0+$/, "") : "")}
                      </span>
                      : betSlipData.oddsType === "fancy" ? (
                        null
                      ) :
                        <span className={`${!isNaN(betSlipData.position[other.selectionid]) && parseFloat(betSlipData.position[other.selectionid]).toFixed(2).replace(/\.?0+$/, "") < 0 ? "text-red-500" : "text-green-700"} font-bold col-12 text-center`}>
                          {(!isNaN(betSlipData.position[other.selectionid]) ? parseFloat(betSlipData.position[other.selectionid]).toFixed(2).replace(/\.?0+$/, "") : "")}
                        </span>
                    }
                  </div>
                </React.Fragment>
              )) :
                null
              }
            </div>
          </div>

          <div className='py-1 px-2 flex space-x-2 items-center'>
            <RiInformationFill size={25} className='text-[#F37027]' />
            <span className='text-sm font-light text-[#F37027]'>Min Bet: 100 Max Bet: 25000 Max Winning: 100000</span>
          </div>
        </div>

        {betLoading === true ? (
          <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
            <div role="status" className='text-red-900 font-bold text-xxl'>
              <BetPlaceCounter />
            </div>
          </div>
        ) : (
          null)}


      </div>

    </section>
  )
}










        {/* {betLoading === true ? (
          <div className='border-2 bg-black opacity-80 rounded-lg h-96 w-full absolute top-0 flex justify-center items-center'>
            <div role="status" className='text-red-900 font-bold text-xl'>
              <svg aria-hidden="true" class="w-8 h-8 text-red-900 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
              </svg>
             
            </div>
          </div>
        ) : (
          null)} */}