import React, { useState, useEffect } from 'react';
import { Route, Routes, BrowserRouter, useNavigate } from 'react-router-dom'
import './App.css';
import Login from './pages/Login/Login'
import { Home } from './pages/Home/Home'
import PublicMatchView from './pages/PublicMatchView/PublicMatchView';
import AboutUs from "./pages/Public/AboutUs/AboutUs"
import AffillatePartner from "./pages/Public/Affiliate/Affiliate"
import FaqsPage from "./pages/Public/FaqsPage/FaqsPage"
import Lottery from "./pages/Public/Lottery/Lottery"
import PrivacyPolicy from "./pages/Public/PrivacyPolicy/PrivacyPolicy"
import Promotions from "./pages/Public/Promotions/Promotions"
import TermCondition from "./pages/Public/TearmCondition/TermCondition"
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import Layout from './component/Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import Register from './pages/Register/Register';
import { CONST } from "./apis/userApis"
import { userAction } from './redux/action/userAction';


function App(props) {

  const dispatch = useDispatch();

  // useEffect(() => {
  //   DomainSetting()
  // }, [])
  // const DomainSetting = () => {
  //   const fetchData = async () => {
  //     try {

  //       let domainSetting = {
  //         "domainUrl": CONST.BASE_DOMAIN
  //       };
  //       const storedDomainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));
  //       if (!storedDomainSetting) {
  //         const response = await dispatch(userAction.domainSettingByDomainName(domainSetting));
  //         const fetchedDomainSetting = response.payload;
  //         localStorage.setItem("clientdomainSetting", JSON.stringify(fetchedDomainSetting));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  //   fetchData();
  //   const intervalId = setInterval(fetchData, 1000 * 60 * 10);
  //   return () => clearInterval(intervalId);

  // }
  // all clear and set get again 

  // const DomainSetting = () => {
  //   const fetchData = async () => {
  //     try {
  //       let domainSetting = {
  //         "domainUrl": CONST?.BASE_DOMAIN
  //       };
  //       const storedDomainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));

  //       if (!storedDomainSetting) {
  //         const response = await dispatch(userAction.domainSettingByDomainName(domainSetting));
  //         const fetchedDomainSetting = response.payload;
  //         localStorage.setItem("clientdomainSetting", JSON.stringify(fetchedDomainSetting));
  //       } else {
  //         localStorage.removeItem("clientdomainSetting");
  //         const response = await dispatch(userAction.domainSettingByDomainName(domainSetting));
  //         console.log("response", response);
  //         const fetchedDomainSetting = response.payload;
  //         localStorage.setItem("clientdomainSetting", JSON.stringify(fetchedDomainSetting));
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData();
  //   const duration = 1000 * 60 * 10; // 10 min 
  //   const intervalId = setInterval(fetchData, duration);
  //   return () => clearInterval(intervalId);
  // }

  useEffect(() => {
    const setDomainSetting = async () => {
      try {
        let domainSetting = {
          "domainUrl": CONST?.BASE_DOMAIN
        };
        const storedDomainSetting = JSON.parse(localStorage.getItem("clientdomainSetting"));

        if (!storedDomainSetting) {
          const response = await dispatch(userAction.domainSettingByDomainName(domainSetting));
          const fetchedDomainSetting = response?.payload;
          localStorage.setItem("clientdomainSetting", JSON.stringify(fetchedDomainSetting));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    setDomainSetting();

    const intervalId = setInterval(() => {
      localStorage.removeItem("clientdomainSetting");
    }, 1000 * 60 * 10);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/' element={<Home />} />
          <Route path='/viewMatch/:marketId?/:eventId?' element={<PublicMatchView  {...props} />} />
          <Route path='/affillate-partner' element={<AffillatePartner />} />
          <Route path='/term-conditon' element={<TermCondition />} />
          <Route path='/promotions' element={<Promotions />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/faqs' element={<FaqsPage />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/loyalty' element={<Lottery />} />
          <Route path='/registration' element={<Register />} />
          <Route path="/app/*" element={<PrivateRoute element={<Layout {...props} />} />} />


        </Routes>
      </BrowserRouter>
    </SkeletonTheme>

  );
}

function PrivateRoute({ element }) {
  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem('clientUser');
    if (!login) {
      navigate('/');
    }
  }, []);

  return <>{element}</>;
}
export default App;
